import type { IntlShape } from 'react-intl';

export function getLocalizedDate(intl: IntlShape, dateISO: string): string {
  if (dateISO) {
    const date = new Date(dateISO);

    return intl.formatDate(date);
  }

  return '';
}

export function getLocalizedTime(intl: IntlShape, dateISO: string): string {
  if (dateISO) {
    const date = new Date(dateISO);

    return intl.formatTime(date);
  }

  return '';
}

export function getLocalizedDateTime(intl: IntlShape, dateISO: string): string {
  if (dateISO) {
    const date = getLocalizedDate(intl, dateISO);
    const time = getLocalizedTime(intl, dateISO);

    return `${date} - ${time}`;
  }

  return '';
}
