import { buildNumber } from '../../buildNumber';

const FooterComponent = () => (
  <div className="footer">
    <div>Copyright © 2023 nowomed</div>
    <div>
      Build #:
      {' '}
      { buildNumber }
    </div>
  </div>
);
export default FooterComponent;
