/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { MedicationalEfficacy } from '../../../types/ninox.types';
import { FormDialog, FormField } from '../../molecules';

type AddMedicationEfficacyDialogProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  onAddMedicationEfficacy: (medicationEfficacy: MedicationalEfficacy) => void;
  medicationEfficacy: MedicationalEfficacy | null;
  clearEditedMedicationEfficacy: () => void;
};

export default function AddMedicationEfficacyDialog(props: AddMedicationEfficacyDialogProps): JSX.Element {
  const intl = useIntl();

  const emptyMedicationEfficacy: MedicationalEfficacy = {
    effectiveness: '11',
  };

  const [medicationEfficacy, setMedicationEfficacy] = useState<MedicationalEfficacy>({ ...emptyMedicationEfficacy });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeFormField = (event: React.ChangeEvent<any>): void => {
    const { name, value } = event.target;
    setMedicationEfficacy((prevMedicationEfficacy) => ({ ...prevMedicationEfficacy, [name]: value }));
  };

  const onSave = () => {
    props.onAddMedicationEfficacy(medicationEfficacy);
    props.setShow(false);
    setMedicationEfficacy({ ...emptyMedicationEfficacy });
  };

  const onClose = () => {
    props.clearEditedMedicationEfficacy();
    setMedicationEfficacy({ ...emptyMedicationEfficacy });
  };

  useEffect(() => {
    if (props.medicationEfficacy) {
      setMedicationEfficacy(props.medicationEfficacy);
    }
  }, [props.medicationEfficacy]);

  return (
    <FormDialog
      title={intl.formatMessage({ id: 'patient.editMedicationEfficacy' })}
      show={props.show}
      setShow={props.setShow}
      onSave={onSave}
      onClose={onClose}
    >
      <FormField
        type='select'
        placeholder={intl.formatMessage({ id: 'patient.effectiveness' })}
        name='effectiveness'
        value={medicationEfficacy.effectiveness.toString()}
        onChange={onChangeFormField}
        choices={[
          ['11', '0'],
          ['1', '1'],
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
          ['6', '6'],
          ['7', '7'],
          ['8', '8'],
          ['9', '9'],
          ['10', '10'],
        ]}
      >
        <FormattedMessage id='patient.effectiveness' />
      </FormField>
    </FormDialog>
  );
}
