import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSliceSelector } from '../features/auth/authSlice';

export const AuthGuard = ({ children }) => {
  const { auth } = useSelector(authSliceSelector);
  const isAuth = auth;
  if (!isAuth) {
    return <Navigate to='/login' replace />;
  }

  return children;
};

export const GuestGuard = ({ children }) => {
  const { auth } = useSelector(authSliceSelector);
  const isAuth = auth;

  if (isAuth) {
    return <Navigate to='/dashboard' replace />;
  }
  
  return children;
};
