import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import type { Appointment } from '../../../types/ninox.types';
import { getLocalizedDateTime } from '../../../utils';
import { Button } from '../../atoms';
import { Modal } from '../../molecules';
import style from './AppointmentTable.module.scss';
import { useSelector } from 'react-redux';
import { patientInfoSliceSelector } from '../../../features/patientInfo/patientInfoSlice';

const cx = classNames.bind(style);

export default function AppointmentTable(): JSX.Element {
  const intl = useIntl();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteAppointmentId, setDeleteAppointmentId] = useState(0);
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);
  const appointmentsClasses = cx({});

  const openDeleteModal = (appointmentId: number) => {
    setDeleteAppointmentId(appointmentId);
    setShowDeleteModal(true);
  };

  return (
    <div className={appointmentsClasses}>
      <Modal
        type='deleteAppointment'
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        appointmentId={deleteAppointmentId}
        patientId={patientInfoUpdate.id}
      />
      <Table hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>
              <FormattedMessage id='appointment' />
            </th>
            <th>
              <FormattedMessage id='doctor' />
            </th>
            <th>
              <FormattedMessage id='appointmentType' />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(patientInfoUpdate.appointment && patientInfoUpdate.appointment.length === 0) && (
            <tr>
              <td colSpan={4}>
                <FormattedMessage id='noAppointments' />
              </td>
            </tr>
          )}
          {(patientInfoUpdate.appointment && patientInfoUpdate.appointment.length > 0)&& patientInfoUpdate.appointment.map((appointment: Appointment) => (
            <tr key={appointment.id}>
              <td>{String(appointment.id).padStart(5, '0')}</td>
              <td>
                {getLocalizedDateTime(intl, appointment.startDate)} <b>-</b>
                {getLocalizedDateTime(intl, appointment.endDate)}
              </td>
              <td>
                {String(appointment.doctorId).padStart(5, '0')} - {appointment.doctorName}
              </td>
              <td>{appointment.type}</td>
              <td>
                <Button onClick={() => openDeleteModal(Number(appointment.id!))} variant={'danger'}>
                  Löschen
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
