import { createSlice } from '@reduxjs/toolkit';
import type { Slot as SlotType } from '../../types/slot.type';

const PRACTICES_SLICE = 'PRACTICES';

const initialState = {
  practices: [],
  loadPractices: false,
  freeAppointments: [],
  isDisplayInfo: false,
};

export const practicesSlice = createSlice({
  name: PRACTICES_SLICE,
  initialState,
  reducers: {
    setPractices: (state, action) => {
      state.practices = action.payload;
    },
    setLoadPractices: (state, action) => {
      state.loadPractices = action.payload;
    },
    setfreeAppointments: (state, action) => {
      state.freeAppointments = action.payload;
    },
    setIsDisplayInfo: (state, action) => {
      state.isDisplayInfo = action.payload;
    },
    fetchPractices: () => {}, // call watcherSaga.
    getFreeAppointments: () => {},
  },
});

export const {
  setPractices,
  fetchPractices,
  setLoadPractices,
  getFreeAppointments,
  setfreeAppointments,
  setIsDisplayInfo,
} = practicesSlice.actions;

export const practicesSliceSelector = (state: any) => state.practices;

export default practicesSlice.reducer;
  