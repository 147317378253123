/* eslint-disable require-yield */
import { takeLatest, put, call } from 'redux-saga/effects';
import { login, setCurrentUser, setAuthError, setAuth} from './authSlice';
import { loginUser } from '../../utils/apiCalls';
export interface ResponseUserData{
  email: string,
  id: string,
  isActivated: boolean,
  isDeleted: boolean,
  isPrivacyPolicyAgree: boolean,
  isTermsAgree: boolean,
  name: string,
  ninoxId: number,
  role: number
  token: string
}
export interface ResponseData{
  data: ResponseUserData | null,
  status: number,
  statusText: string,
}

function* loginSaga(action:any) {
  try {
    const user: any = {
      email: action.payload.username,
      password: action.payload.password,
      stayLoggedIn: true
    };
    const res: ResponseData = yield call(loginUser, user);
    if (res.data) {
      const { token } = res.data;
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setAuth(token));
        yield put(setAuthError(null));
        // add in state the correct user data from 
        yield put(setCurrentUser(res.data));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

export function* watchAuthSaga() {
  yield takeLatest(login.type, loginSaga);
}
