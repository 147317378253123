import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { PrescriptionTask } from '../../types/prescriptionTask.types';
import classNames from 'classnames/bind';
import style from './Prescription.module.scss';
import { Dimmer, Dropdown, Header, List, Loader, Segment, Button } from 'semantic-ui-react';
import { prescriptionsSliceSelector, fetchPrescriptions, filterPrescriptionList, setIsDisplayPrescriptionInfo } from './prescriptionsSlice';
import { PrescriptionDetails } from './PrescriptionDetails/PrescriptionDetails';
const cx = classNames.bind(style);

export default function PrescriptionsComponent() {
  const [hubFilter, setHubFilter] = useState(1);
  const { prescriptionsOpen, prescriptionsClose, isDisplayPrescriptionPage } = useSelector(prescriptionsSliceSelector);
  const [selectedTask, setSelectedTask] = useState({});
  const dispatch = useDispatch();

  const friendOptions = [
    {
      key: 'NORD',
      text:  <FormattedMessage id='north' />,
      value: 1,
    },
    {
      key: 'OST',
      text: <FormattedMessage id='east' />,
      value: 2,    },
    {
      key: 'SÜD',
      text: <FormattedMessage id='south' />,
      value: 3,    },
    {
      key: 'WEST',
      text: <FormattedMessage id='west' />,
      value: 4,
    },
  ];

  useEffect(() => {   
    dispatch(fetchPrescriptions());
  }, [dispatch]);


  const getDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    return `${(`0${date.getDate()}`).slice(-2)}.${(`0${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()}`;
  };

  const getTypText = (typ: number): string => {
    if (typ === 1) {
      return 'Rezept nach Termin';
    }
    if (typ === 2) {
      return 'Folgerezept';
    }
    return 'Unbekannt';
  };
  
  const changeHubFilter = (e: any, { _name, value }: any) => {
    setHubFilter(Number(value));
    dispatch(filterPrescriptionList(value));
  };
  const subheadingClasses = cx({ PrescriptionSubHeading: true });

  const onPrescriptionDetailPage = (item: PrescriptionTask) => {
    dispatch(setIsDisplayPrescriptionInfo(true));
    setSelectedTask(item);
  };
  
  const renderPrescriptionsList = (prescriptions: Array<PrescriptionTask>, listType: string) => (!prescriptions.length
    ? (
      <Segment raised inverted className={ listType === 'open' ?'segment-style': 'segment-style-close' }>
        { listType === 'open' ? <FormattedMessage id='notOpenRecipes' /> : <FormattedMessage id='notClosedRecipes' /> }
      </Segment>
    )
    :  prescriptions.map((item: PrescriptionTask, i:number) => (
      <Segment.Group horizontal key={i}>
        <Segment raised inverted className={ listType === 'open' ?'segment-style': 'segment-style-close' }>
          <List>
            <List.Item>
              <List.Content> <FormattedMessage id='patient' />: <b> {item.patient} ({item.patientId}) </b></List.Content>
            </List.Item>
            <List.Item>
              <List.Content>Date:{getDate(item.date)}</List.Content>
            </List.Item>
          </List>
        </Segment>
        <Segment raised inverted className={ listType === 'open' ?'segment-style': 'segment-style-close' }>
          <List>
            <List.Item>
              <List.Content> <FormattedMessage id='doctorTitle' />: <b>{item.doctor}</b></List.Content>
            </List.Item>
            <List.Item>
              <List.Content> <FormattedMessage id='type' />: {getTypText(item.type)}</List.Content>
            </List.Item>
          </List>
        </Segment>
        <Segment raised inverted className={ listType === 'open' ?'segment-style': 'segment-style-close' }>
          <List>
            <List.Item>
              <List.Content> <FormattedMessage id='doctorComment' /> :</List.Content>
            </List.Item>
            <List.Item>
              <List.Content>{item.notes ? <b>{item.notes}</b> : '-'}</List.Content>
            </List.Item>
          </List>
        </Segment>
        { listType === 'open' &&
        <Segment raised inverted className='segment-style'>
          <Button primary floated='right' onClick={() => onPrescriptionDetailPage(item)}><FormattedMessage id='edit' /></Button>
        </Segment> }
      </Segment.Group>
    ))
  );

  const prescriptionsListLoading = (
    <Dimmer active inverted>
      <Loader className="loading-spinner" active inline='centered'><FormattedMessage id='loading' /></Loader>
    </Dimmer>
  );

  return (
    <div className="details_container">
      {!isDisplayPrescriptionPage ?
        <div>
          <Header as='h1'> <FormattedMessage id='prescriptionsHeader' /></Header>
          <span>
            <FormattedMessage id='HUBChoice' />{' '}
            <Dropdown
              inline
              options={friendOptions}
              value={hubFilter}
              onChange={changeHubFilter}
            />
          </span>
          <div className={subheadingClasses}> <FormattedMessage id='openRecipes' /></div>
          <div style={{overflow: 'auto', height:'35rem'}}>
            { prescriptionsOpen ? renderPrescriptionsList(prescriptionsOpen, 'open'): prescriptionsListLoading }
          </div>
          <div className={subheadingClasses}><FormattedMessage id='closeRecipes' /></div>
          <div style={{overflow: 'auto', height:'30rem'}}>
            { 
              prescriptionsClose ? renderPrescriptionsList(prescriptionsClose, 'close'): prescriptionsListLoading 
            }
          </div>
        </div>
        :
        <PrescriptionDetails item={selectedTask}/>
      }
    </div>
  );
}
