import { IntlProvider } from 'react-intl';
import {useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { GuestGuard, AuthGuard } from './routes/WrappedRoutes';
import LoginComponent from './features/auth/LoginPage';
import SideBarComponent from './features/SideBarComponent';
import DashboardComponent from './features/dashboard/dashboard.component';
import PatientsComponent from './features/patients/patients.component';
import DoctorsComponent from './features/doctors/doctors.component';
import AppointmentsComponent from './features/appointments/appointments.component';
import PracticesComponent from './features/practices/practices.component';
import PrescriptionsComponent from './features/prescriptions/prescriptions.component';
import messages from './translation/messages.json';
import './App.scss';
import './styles/styles.scss';
import { globalSliceSelector } from './app/appSlice';
import PatientInfoComponent from './features/patientInfo/PatientInfo.component';


const App = () => {
  const { language } = useSelector(globalSliceSelector);

  return (
    <IntlProvider locale={language} messages={(messages as any)[language]} defaultLocale='de'>
      <BrowserRouter>
        <SideBarComponent />
        <Routes>
          <Route path='/' element={<AuthGuard><DashboardComponent /></AuthGuard>} />
          <Route path='/login' element={<GuestGuard><LoginComponent /></GuestGuard>} />
          <Route path='/dashboard' element={<AuthGuard><DashboardComponent /></AuthGuard>} />
          <Route path='/patients' element={<AuthGuard><PatientsComponent /></AuthGuard>} />
          <Route path='/prescriptions' element={<AuthGuard><PrescriptionsComponent /></AuthGuard>} />
          <Route path='/appointments' element={<AuthGuard><AppointmentsComponent /></AuthGuard>} />
          <Route path='/doctors' element={<AuthGuard><DoctorsComponent /></AuthGuard>} />
          <Route path='/practices' element={<AuthGuard><PracticesComponent /></AuthGuard>} />
          <Route path='*' element={<Navigate to='/login' />}/>
          <Route path='/patients/:ninoxId' element={<AuthGuard><PatientInfoComponent /></AuthGuard>} />
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  );
};


export default App;
