/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { Complaints } from '../../../types/ninox.types';
import FormDialog from '../../molecules/FormDialog/FormDialog';
import FormField from '../../molecules/FormField/FormField';

type AddComplaintsDialogProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  onAddComplaints: (complaints: Complaints) => void;
  complaints: Complaints | null;
  patientId: string;
  clearEditedComplaints: () => void;
};

export default function AddComplaintsDialog(props: AddComplaintsDialogProps): JSX.Element {
  const intl = useIntl();

  const emptyComplaints: Complaints = {
    average: '11',
    maximum: '11',
    minimal: '11',
    maximumStrength: '11',
    current: '11',
    queryDate: '2021-01-01',
  };

  const [complaints, setComplaints] = useState<Complaints>({ ...emptyComplaints });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeFormField = (event: React.ChangeEvent<any>): void => {
    const { name, value } = event.target;
    setComplaints((prevComplaints) => ({ ...prevComplaints, [name]: value }));
  };

  const onSave = () => {
    props.onAddComplaints(complaints);
    props.setShow(false);
    setComplaints({ ...emptyComplaints });
  };

  const onClose = () => {
    props.clearEditedComplaints();
    setComplaints({ ...emptyComplaints });
  };

  useEffect(() => {
    if (props.complaints) {
      setComplaints(props.complaints);
    }
  }, [props.complaints]);

  return (
    <FormDialog
      title={intl.formatMessage({ id: 'patient.editComplaints' })}
      show={props.show}
      setShow={props.setShow}
      onSave={onSave}
      onClose={onClose}
    >
      <FormField
        type='select'
        placeholder={intl.formatMessage({ id: 'patient.enterAverage' })}
        name='average'
        value={complaints.average.toString()}
        onChange={onChangeFormField}
        choices={[
          ['11', '0'],
          ['1', '1'],
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
          ['6', '6'],
          ['7', '7'],
          ['8', '8'],
          ['9', '9'],
          ['10', '10'],
        ]}
      >
        <FormattedMessage id='patient.average' />
      </FormField>
      <FormField
        type='select'
        placeholder={intl.formatMessage({ id: 'patient.enterMaximal' })}
        name='maximum'
        value={complaints.maximum.toString()}
        onChange={onChangeFormField}
        choices={[
          ['11', '0'],
          ['1', '1'],
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
          ['6', '6'],
          ['7', '7'],
          ['8', '8'],
          ['9', '9'],
          ['10', '10'],
        ]}
      >
        <FormattedMessage id='patient.maximal' />
      </FormField>
      <FormField
        type='select'
        placeholder={intl.formatMessage({ id: 'patient.enterMinimal' })}
        name='minimal'
        value={complaints.minimal.toString()}
        onChange={onChangeFormField}
        choices={[
          ['11', '0'],
          ['1', '1'],
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
          ['6', '6'],
          ['7', '7'],
          ['8', '8'],
          ['9', '9'],
          ['10', '10'],
        ]}
      >
        <FormattedMessage id='patient.minimal' />
      </FormField>
      <FormField
        type='select'
        placeholder={intl.formatMessage({ id: 'patient.enterMaximumStrength' })}
        name='maximumStrength'
        value={complaints.maximumStrength.toString()}
        onChange={onChangeFormField}
        choices={[
          ['11', '0'],
          ['1', '1'],
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
          ['6', '6'],
          ['7', '7'],
          ['8', '8'],
          ['9', '9'],
          ['10', '10'],
        ]}
      >
        <FormattedMessage id='patient.maximumStrength' />
      </FormField>
      <FormField
        type='select'
        placeholder={intl.formatMessage({ id: 'patient.enterCurrent' })}
        name='current'
        value={complaints.current.toString()}
        onChange={onChangeFormField}
        choices={[
          ['11', '0'],
          ['1', '1'],
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
          ['6', '6'],
          ['7', '7'],
          ['8', '8'],
          ['9', '9'],
          ['10', '10'],
        ]}
      >
        <FormattedMessage id='patient.current' />
      </FormField>
      <FormField type='date' name='queryDate' value={complaints.queryDate} onChange={onChangeFormField}>
        <FormattedMessage id='patient.queryDate' />
      </FormField>
    </FormDialog>
  );
}
