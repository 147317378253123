/* eslint-disable no-use-before-define */
import React from 'react';
import classNames from 'classnames/bind';
import style from './Form.module.scss';

const cx = classNames.bind(style);

type FormProps = {
  children?: React.ReactNode;
  name: string;
  className?: Record<string, boolean>;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  style?: Record<string, string>;
};

export default function Form(props: FormProps): JSX.Element {
  const formClasses = cx({ Form: true, ...props.className });

  return (
    <form className={formClasses} name={props.name} onSubmit={props.onSubmit} style={props.style}>
      {props.children}
    </form>
  );
}
