/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { PracticeService } from '../../services';
import { StatusMessage } from '../../components/atoms';
import style from './PracticeDetails.module.scss';
import { practicesSliceSelector, getFreeAppointments, setIsDisplayInfo, setfreeAppointments } from './practicesSlice';
import { Button, Segment, Tab, Table, Header } from 'semantic-ui-react';
import { Modal } from '../../components/molecules';

const cx = classNames.bind(style);
export default function PracticeElement(props: any) {
  const practicesClasses = cx({ PracticeStats: true });

  const [statusType, setStatusType] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [arrayOfDates, setArrayOfDates] = useState<string[]>([]);
  const { freeAppointments } = useSelector(practicesSliceSelector);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const dispatch = useDispatch();

  const setStatus = (type: string, message: string) => {
    setStatusType(type);
    setStatusMessage(message);
  };

  const isSameDay = (date1: string, date2: string) => {
    if (new Date(date1).toDateString() === new Date(date2).toDateString()) {
      return true;
    }

    return false;
  };

  const getHourMinutesFormat = (date: string): string => {
    const newFormat = new Date(date).toLocaleString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return newFormat;
  };

  const getDateFormat = (date: string | number | Date) => {
    const newFormat = new Date(date).toLocaleString('de-DE', {
      weekday: 'short',
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });

    return newFormat.slice(0, 2) + newFormat.slice(3);
  };

  const getAppointmentStats = async () => {
    const response = await PracticeService.getAppointmentStats();
    if (response.error) {
      setStatus('error', 'Error loading PracticeStats.');
    }
  };

  const getAllData = async () => {
    await getAppointmentStats();
  };

  useEffect(() => {
    getAllData();
    // Rename the getFreeAppointments to slots PLEASEEEEEEE!!!!!!
    if (!freeAppointments.length) dispatch(getFreeAppointments(props.practice.id));
  }, []);

  useEffect(() => {
    const newArrOfDates: string[] = [];

    freeAppointments?.forEach((slot: any) => {
      const ArrayIncludesDate = newArrOfDates.some((date: string) => isSameDay(slot.start, date));
      if (ArrayIncludesDate) {
        return false;
      }

      newArrOfDates.push(new Date(slot.start).toDateString());
    });

    newArrOfDates.sort((a: string, b: string) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      const currentDate = new Date();

      const diffA = Math.abs(dateA.getTime() - currentDate.getTime());
      const diffB = Math.abs(dateB.getTime() - currentDate.getTime());

      return diffA - diffB;
    });


    setArrayOfDates(newArrOfDates);
  }, [freeAppointments]);

  const onBackButton = () => {
    dispatch(setfreeAppointments([]));
    dispatch(setIsDisplayInfo(false));
  };
  const reloadSlots = () => {
    dispatch(getFreeAppointments(props.practice.id));
    console.log('reloadSlots!');
  };
  const panes = [
    { menuItem: 'Available Appointments',
      render: () => (
        <Tab.Pane className='list-doctor-appointment'>
          {!arrayOfDates.length ? (
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={9}>
                <Header as="h4">No Available Appointments found!</Header>
              </Table.Cell>
            </Table.Row>
          ) 
            :
            <div>
              {arrayOfDates?.map((element: string) => {
                const dateFollowAppointments = freeAppointments?.filter((slot: any) => isSameDay(element, slot.start));

                return (
                  <Segment raised className={style.PracticeSecondRowContainer} key={String(element)}>
                    <div className={style.DateContainer}>
                      <p className={style.BlueTextBold}>{getDateFormat(element)}</p>
                    </div>

                    {dateFollowAppointments && dateFollowAppointments.length !== 0 && (
                      <div>
                        <p className={style.BlueText}>Erstgespräche</p>
                        <div className={style.SlotContainer}>
                          {dateFollowAppointments.sort((a: any, b: any) => new Date(a.start).getTime() - new Date(b.start).getTime()).map((slot: any) => (
                            <div className={style.BlueSlot} key={`${slot.start}-${slot.id}-${slot.doctor.doctorName}`}
                              onClick={() => { setSelectedSlot(slot); setShowAppointmentModal(true); }}>
                              <div className={style.SlotTextBlue}>{`${getHourMinutesFormat(
                                slot.start,
                              )} - ${getHourMinutesFormat(slot.end)} Uhr ${slot.doctor.doctorName}`}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Segment>
                );
              })}
            </div>

          }
        </Tab.Pane>
      )
    }
  ];
  return (
    <main className={practicesClasses}>
      <div>
        {showAppointmentModal && (
          <Modal
            type='practiceAppointment'
            show={showAppointmentModal}
            setShow={setShowAppointmentModal}
            appointment={selectedSlot}
            reloadSlots={reloadSlots}
            onSubmit={() => { }}
          />
        )}
      </div>
      <Button onClick={()=> onBackButton()} circular basic icon='arrow left' style={{marginBottom: 9}}/>
      <StatusMessage type={statusType} message={statusMessage} />
      <Segment raised inverted className='segment-style'>
        <article className={style.RowContainer}>
          <div className={style.PracticeTitleRowContainer}>
            <p>ID</p>
            <p>{props.practice.id}</p>
          </div>
          <div className={style.PracticeTitleRowContainer}>
            <p>Name:</p>
            <p><b>{props.practice.name}</b></p>
          </div>
          <div className={style.PracticeTitleRowContainer}>
            <p>E-mail:</p>
            <p>{props.practice.email}</p>
          </div>
        </article>
        <article>
          <div className={style.PracticeStatsRowContainer}>
            <p><b>Auslastung 14 Tage:</b></p>
            <div>
              <span
                className={props.practice.twoWeekStats.offlineFirstAppointments.percentage < 80 ? style.progressBarLabel : style.progressBarLabelRed}
              >{`Erstgespräche: ${props.practice.twoWeekStats.offlineFirstAppointments.total} Std. verfügbar (${props.practice.twoWeekStats.offlineFirstAppointments.booked} Std. gebucht)`}</span>
              <div className={props.practice.twoWeekStats.offlineFirstAppointments.percentage < 80 ? style.resourcesBar : style.resourcesBarRed}>
                <div
                  className={props.practice.twoWeekStats.offlineFirstAppointments.percentage < 80 ? style.resourcesBarProgress : style.resourcesBarProgressRed}
                  style={{ width: `${props.practice.twoWeekStats.offlineFirstAppointments.percentage}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className={style.PracticeStatsRowContainer}>
            <p><b>Auslastung total:</b></p>
            <div>
              <span
                className={props.practice.totalStats.offlineFirstAppointments.percentage < 80 ? style.progressBarLabel : style.progressBarLabelRed}
              >{`Erstgespräche: ${props.practice.totalStats.offlineFirstAppointments.total} Std. verfügbar (${props.practice.totalStats.offlineFirstAppointments.booked} Std. gebucht)`}</span>
              <div className={props.practice.totalStats.offlineFirstAppointments.percentage < 80 ? style.resourcesBar : style.resourcesBarRed}>
                <div
                  className={props.practice.totalStats.offlineFirstAppointments.percentage < 80 ? style.resourcesBarProgress : style.resourcesBarProgressRed}
                  style={{ width: `${props.practice.totalStats.offlineFirstAppointments.percentage}%` }}
                ></div>
              </div>
            </div>
          </div>
        </article>
      </Segment>
      <Tab panes={panes} />
    </main>
  );
}
