import { Accordion, Icon, Progress } from 'semantic-ui-react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { patientInfoSliceSelector } from '../patientInfoSlice';

type EditPatientProps = {
  activeIndex: number,
  handleClick: (arg0: any, arg1: any) => void,
  onChangeFormField: (event: React.ChangeEvent<any>) => void,
  };

export default function PatientProgress({
  activeIndex,
  handleClick,
}: EditPatientProps ){

  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  return(
    <><Accordion.Title
      active={activeIndex === 11}
      index={11}
      onClick={(event, data) => handleClick(event, data)}
    >
      <Icon name='dropdown' />
    Progress
    </Accordion.Title>
    <Accordion.Content active={activeIndex === 11} >
      <Col style={{width: '60%' }}>
        Total
        <Progress percent={patientInfoUpdate.progress?.total} progress color='blue' size='small' style={{marginTop: 8, marginBottom: 10}}/>
        First Presentation
        <Progress percent={patientInfoUpdate.progress?.firstPresentation} progress color='blue' size='small'/>
        Secondary Diagnosis
        <Progress percent={patientInfoUpdate.progress?.secondaryDiagnosis} progress color='blue' size='small'/>
        Documents
        <Progress percent={patientInfoUpdate.progress?.documents} progress color='blue' size='small'/>
      </Col>
    </Accordion.Content></>
  );
}