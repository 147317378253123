/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col, Row, Table } from 'react-bootstrap';
import { Address } from '../../../types/ninox.types';
import { AddAddressDialog } from '../../../components/organisms';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames/bind';
import style from '../../patientInfo/EditPatient.module.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceSelector } from '../../auth/authSlice';
import { patientInfoSliceSelector, setPatientInfoUpdate } from '../patientInfoSlice';
import { toast } from 'react-toastify';
import { deletePatientAddress } from '../../../utils/apiCalls';

type EditPatientProps = {
  activeIndex: number,
  handleClick: (arg0: any, arg1: any) => void,
  deleteListElem: (type: string, elem: any) => void,
  };

export default function PatientAddress({activeIndex, handleClick, deleteListElem }: EditPatientProps)  {
  const cx = classNames.bind(style);
  const intl = useIntl();
  const dispatch = useDispatch();

  const { token } = useSelector(authSliceSelector);
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  const [addressToEdit, setAddressToEdit] = useState<Address | null>(null);
  const [showAddressDialog, setShowAddressDialog] = useState(false);

  const onAddAddress = (address: Address): void => {
    if (addressToEdit) {
      if (patientInfoUpdate?.address) {
        for (let i = 0; i < patientInfoUpdate.address.length; i += 1) {
          if (patientInfoUpdate.address[i] === addressToEdit) {
            const addressUpdate = [...patientInfoUpdate.address];
            addressUpdate[i] = address;
            dispatch(setPatientInfoUpdate({...patientInfoUpdate, address: addressUpdate}));
          }
        }
      }

      // Reset complaints to edit after update. This will trigger the adding when the dialog is opened next time.
      setAddressToEdit(null);
    } else if (typeof patientInfoUpdate.address === 'undefined') {
      dispatch(setPatientInfoUpdate({...patientInfoUpdate, address: [address]}));
    } else {
      const addressUpdate = [...patientInfoUpdate.address];
      addressUpdate.push(address);
      dispatch(setPatientInfoUpdate({...patientInfoUpdate, address: addressUpdate}));
    }
  };

  const editAddress = (address: Address): void => {
    setAddressToEdit(address);
    setShowAddressDialog(true);
  };

  const deleteAddress = (address: Address): void => {
    if (!patientInfoUpdate.id) {
      toast.error(intl.formatMessage({ id: 'patient.error.deleteAddress' }));
      console.error('ID in route not set when trying to delete address');

      return;
    }
    if (!token) {
      toast.error(intl.formatMessage({ id: 'patient.error.notLoggedIn' }));
      console.error('Not logged in when trying to delete address');

      return;
    }
    // This address does not come from server and does not have to be deleted from server.
    if (!address.id) {
      return;
    }

    toast.info(intl.formatMessage({ id: 'patient.deletingAddress' }));
    deletePatientAddress(patientInfoUpdate.id.toString(), Number(address.id))
      .then(() => {
        toast.success(intl.formatMessage({ id: 'patient.addressDeleted' }));
      })
      .catch((error) => {
        toast.error(intl.formatMessage({ id: 'patient.error.deleteAddress' }));
        console.error('Error while deleting address', error.response);
      });
  };

  return(
    <><Accordion.Title
      active={activeIndex === 2}
      index={2}
      onClick={(event, data) => handleClick(event, data)}
    >
      <Icon name='dropdown' />
      <FormattedMessage id='patient.addresses' />
    </Accordion.Title>
    <Accordion.Content active={activeIndex === 2}>
      <Row>
        <Col>
          <div className={cx({ Addresses: true })}>
            {patientInfoUpdate.address && patientInfoUpdate.address.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id='patient.street' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.houseNumber' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.city' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.postcode' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.country' />
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {patientInfoUpdate.address?.map((addressItem: Address, index: number) => (
                    <tr key={addressItem.id}>
                      <td>{addressItem.street}</td>
                      <td>{addressItem.houseNumber}</td>
                      <td>{addressItem.city}</td>
                      <td>{addressItem.postcode}</td>
                      <td>{addressItem.country ? intl.formatMessage({ id: addressItem.country }) : ''}</td>
                      <td>
                        <a
                          href='#'
                          onClick={(event) => {
                            event.preventDefault();
                            editAddress(addressItem);
                          }}
                        >
                          <FormattedMessage id='edit' />
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          onClick={(event) => {
                            event.preventDefault();
                            if (
                            // eslint-disable-next-line no-alert
                              window.confirm(
                                intl.formatMessage(
                                  { id: 'patient.deleteAddressConfirmation' },
                                  { street: addressItem.street, housenumber: addressItem.houseNumber },
                                ),
                              )
                            ) {
                              deleteAddress(addressItem);
                              deleteListElem('address', addressItem);
                            }
                          }}
                        >
                          <FormattedMessage id='delete' />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {(!patientInfoUpdate.address || (patientInfoUpdate.address && !patientInfoUpdate.address.length)) && (
              <p>
                <FormattedMessage id='patient.noAddresses' />
              </p>
            )}
            <Button onClick={() => setShowAddressDialog(true)}>
              <FormattedMessage id='patient.addAddress' />
            </Button>
            <AddAddressDialog
              show={showAddressDialog}
              setShow={setShowAddressDialog}
              onAddAddress={onAddAddress}
              address={addressToEdit}
              patientId={patientInfoUpdate?.id ? patientInfoUpdate.id.toString() : ''}
              clearEditedAddress={() => setAddressToEdit(null)}
            />
          </div>
        </Col>
      </Row>
    </Accordion.Content></>
  );
}