import { all, fork } from 'redux-saga/effects';
import { watchAuthSaga } from '../features/auth/authSaga';
import { watchAppointmentsSaga } from '../features/appointments/appointmentsSaga';
import { watchPatientsSaga } from '../features/patients/patientSaga';
import { watchDoctorsSaga }  from '../features/doctors/doctorsSaga';
import { watchPracticesSaga } from '../features/practices/practicesSaga';
import { watchPrescriptionsSagas } from '../features/prescriptions/prescriptionsSaga';
import { watchPatientInfoSaga } from '../features/patientInfo/patientInfoSaga';

export default function* rootSaga() {
  yield all([
    fork(watchAuthSaga),
    fork(watchAppointmentsSaga),
    fork(watchPatientsSaga),
    fork(watchDoctorsSaga),
    fork(watchPracticesSaga),
    fork(watchPrescriptionsSagas),
    fork(watchPatientInfoSaga),
  ]);
  // yield all([
  //   watchAuthSaga,
  // ]).map(fork);
}
