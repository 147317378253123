import { toast } from 'react-toastify';
import AppointmentService from '../../../../services/appointment.service';
import FormDialog from '../../FormDialog/FormDialog';
import { useDispatch, useSelector } from 'react-redux';
import { patientInfoSliceSelector, setPatientInfoUpdate } from '../../../../features/patientInfo/patientInfoSlice';

export type DeleteAppointmentModalProps = {
  type: 'deleteAppointment';
  show: boolean;
  setShow: (show: boolean) => void;
  appointmentId: number;
  patientId: number;
};

export function DeleteAppointmentModal(props: DeleteAppointmentModalProps): JSX.Element {
  const dispatch = useDispatch();
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  const deleteAppointment = () => {
    AppointmentService.deleteAppointment(props.appointmentId).then(() => {
      props.setShow(false);
      toast.success('Appointment deleted successfully');    
      const updatePatient = { ...patientInfoUpdate, appointment: patientInfoUpdate.appointment?.filter((obj: any) => obj.id !== props.appointmentId) };
      dispatch(setPatientInfoUpdate(updatePatient));
    }).catch((error) => {
      toast.info('Error while deleting appointment');    
      console.error('Error while deleting appointment', error.response);
    });
  };

  return (
    <FormDialog
      title={'Termin löschen'}
      onSave={() => deleteAppointment()}
      onClose={() => props.setShow(false)}
      setShow={() => props.setShow(false)}
      show={props.show}
      textSave={'Löschen'}
      variantSave={'danger'}
    >
      Soll der Termin wirklich gelöscht werden?
    </FormDialog>
  );
}
