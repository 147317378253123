/* eslint-disable @typescript-eslint/ban-ts-comment */
import { takeLatest, put, select, call } from 'redux-saga/effects';
import {
  fetchDoctorsAppointmentsStats,
  setDoctorsAppointmentsStats,
  setLoadDoctors,
  fetchDoctorFreeSlots,
  setdoctorFreeSlots,
  setdoctorFreeDates,
  fetchDoctorAbsences,
  setDocotrAbsences,
  createDoctorAbsence,
  setDoctorAbsenceList,
  deleteAbsense,
  setDeletedAbsence,
  fetchAllDoctors,
  setDoctotrsList,
  saveAbsense,
  setUpdateDoctotrAbsense,
} from './doctorsSlice';
import { authSliceSelector, setAuthError } from '../auth/authSlice';
import _ from 'lodash';
import type { Absence as AbsenceType } from '../../types/absence.type';
import {
  fetchDoctorsAppointmentsStatsCall,
  getFreeDoctorSlots,
  getDoctorAbsences,
  createAbsence,
  deleteDoctorAbsence,
  fetchAllDoctorsCall,
  updateAbsence,
} from '../../utils/apiCalls';
import calculationsService from '../../services/calculations.service';
import { act } from 'react-dom/test-utils';
export interface ResponseData {
  data: Array<any> | null,
  status: number,
  statusText: string,
}

export interface ResponseUpdateAbsenseData {
  code: number,
  data: any,
  message: string,
}

export interface ResponseAbsenceData {
  data: any,
  message: number,
  statusText: string,
}

function* fetchDoctorsAppointmentsStatsSaga() {
  try {
    yield put(setLoadDoctors(true));
    const { token } = yield select(authSliceSelector);
    const res: ResponseData = yield call(fetchDoctorsAppointmentsStatsCall, token);
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setDoctorsAppointmentsStats(_.sortBy(res.data, ['lastName', 'id'])));
        yield put(setLoadDoctors(false));
        yield put(setAuthError(null));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

function* fetchDoctorFreeSlotsSaga(action: any) {
  try {
    const { doctorId, url } = action.payload;
    const res: ResponseData = yield call(getFreeDoctorSlots, doctorId, url);
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        const newArrOfDates: string[] = [];
        if (res.data.length) {
          res.data.map((slot) => {
            const ArrayIncludesDate = newArrOfDates.some((date: string) => calculationsService.isSameDay(slot.start, date));
            if (ArrayIncludesDate) {
              return false;
            }
            newArrOfDates.push(new Date(slot.start).toDateString());
          });
        }
        yield put(setdoctorFreeDates(newArrOfDates));
        yield put(setdoctorFreeSlots(res.data));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

function* fetchDoctorAbsencesSaga(action: any) {
  try {
    const res: ResponseData = yield call(getDoctorAbsences, action.payload);
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setDocotrAbsences(res.data));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

function* createDoctorAbsenceSaga(action: any) {
  try {
    const { absence, id } = action.payload;
    const res: ResponseAbsenceData = yield call(createAbsence, absence, id);
    if (res.data) {
      if (res.data.message !== 'Doctor Absence created') {
        yield put(setAuthError(res.data.message));
      } else {
        yield put(setDoctorAbsenceList(res.data.data));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

function* deleteAbsenseSaga(action: any) {
  try {
    const res: ResponseData = yield call(deleteDoctorAbsence, action.payload);
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setDeletedAbsence(action.payload));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

function* fetchAllDoctorsSagas() {
  try {
    const res: ResponseData = yield call(fetchAllDoctorsCall);
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setDoctotrsList(res.data));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

function* saveAbsenseSagas(action: any) {
  try {
    const res: ResponseData = yield call(updateAbsence, action.payload, action.payload.id);
    if (res.status !== 200) {
      // @ts-ignore
      yield put(setAuthError(res.data.message));
    } else {
      // @ts-ignore
      yield put(setUpdateDoctotrAbsense(res.data.data));
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

export function* watchDoctorsSaga() {
  yield takeLatest(fetchDoctorsAppointmentsStats.type, fetchDoctorsAppointmentsStatsSaga);
  yield takeLatest(fetchDoctorFreeSlots.type, fetchDoctorFreeSlotsSaga);
  yield takeLatest(fetchDoctorAbsences.type, fetchDoctorAbsencesSaga);
  yield takeLatest(createDoctorAbsence.type, createDoctorAbsenceSaga);
  yield takeLatest(deleteAbsense.type, deleteAbsenseSaga);
  yield takeLatest(fetchAllDoctors.type, fetchAllDoctorsSagas);
  yield takeLatest(saveAbsense.type, saveAbsenseSagas);
}
