/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col, Row, Table } from 'react-bootstrap';
import { Remark } from '../../../types/ninox.types';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames/bind';
import style from '../../patientInfo/EditPatient.module.scss';
import { AddRemarkDialog } from '../../../components/organisms';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceSelector } from '../../auth/authSlice';
import { fetchPatientRemarks, patientInfoSliceSelector, setPatientRemarks } from '../patientInfoSlice';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createPatientRemark, deletePatientRemark } from '../../../utils/apiCalls';

type EditPatientProps = {
  activeIndex: number,
  handleClick: (arg0: any, arg1: any) => void,
  };

export default function PatientRemarks({activeIndex, handleClick }: EditPatientProps)  {
  const cx = classNames.bind(style);
  const intl = useIntl();
  const dispatch = useDispatch();

  const { token } = useSelector(authSliceSelector);
  const { patientInfoUpdate, patientRemarks } = useSelector(patientInfoSliceSelector);
  const [showRemarkDialog, setShowRemarkDialog] = useState(false);
  const [remarkToEdit, setRemarkToEdit] = useState<Remark | null>(null);

  useEffect(() => {
    if (patientInfoUpdate.id) {
      if(_.isEmpty(patientRemarks)){
        const payload: any= patientInfoUpdate.id ;
        dispatch(fetchPatientRemarks(payload));
      }
    }
  }, [patientInfoUpdate]);

  /**
   * Editing list entries is done the following way: first the element to edit is set in this component, which also updates a prop
   * of the editing dialog component. The editing component has the element to edit in its own state which is updated when the prop
   * changes. After setting the element to edit, the dialog is opened. When the dialog closes, both states, the one of the editing
   * component and this component are cleared.
   *
   * For adding new elements, the element to edit in this component is empty. The editing component deals with it and initializes
   * empty.
   * @param remark The remark to edit.
   */
  const editRemark = (remark: Remark): void => {
    setRemarkToEdit(remark);
    setShowRemarkDialog(true);
  };

  const onAddRemark = (remark: Remark): void => {
    if (remarkToEdit) {
      // Remarks array will not be empty because we just edited a remark.
      if (patientRemarks) {
        for (let i = 0; i < patientRemarks.length; i += 1) {
          if (patientRemarks[i] === remarkToEdit) {
            const remarksUpdate = [...patientRemarks];
            remarksUpdate[i] = remark;
            dispatch(setPatientRemarks(remarksUpdate));
          }
        }
      }
      setRemarkToEdit(null);
    } else if (typeof patientRemarks === 'undefined') {
      dispatch(setPatientRemarks([remark]));
    } else {
      dispatch(setPatientRemarks([...patientRemarks, remark]));
    }
    if (!remarkToEdit) {
      storeRemark(remark);
    }
  };

  const storeRemark = (remark: Remark): void => {
    if (!token) {
      toast.error(intl.formatMessage({ id: 'patient.error.notLoggedIn' }));    
      return;
    }
    if (!patientInfoUpdate.id) {
      toast.error('Error: Route changed.');    
      return;
    }
    toast.info(intl.formatMessage({ id: 'patient.info.savingRemark' }));    
    createPatientRemark(patientInfoUpdate.id.toString(), remark)
      .then((): string => {
        toast.success(intl.formatMessage({ id: 'patient.info.remarkSaved' }));    
        return 'success';
      })
      .catch((error) => {
        toast.error(intl.formatMessage({ id: 'patient.error.saveRemark' }));    
        console.error('error while saving remark', error.response);
        return 'error';
      })
      .then((status: string) => {
        if (status === 'success') {
          dispatch(setPatientRemarks([...patientRemarks, remark]));
        }
      });
  };

  const deleteRemark = (remark: Remark): void => {
    const patientId = patientInfoUpdate.id;
  
    if (!patientId) {
      return;
    }
    if (!token) {
      console.error('Remark deletion triggerd but user not logged in.');
      return;
    }
    if (!remark.id) {
      console.error('Trying to delete remark with no ID');
      return;
    }
    toast.info(intl.formatMessage({ id: 'patient.info.deletingRemark' }));    
    deletePatientRemark(patientId.toString(), Number(remark.id))
      .then((response) => {
        if (response.status !== 200) {
          toast.error(intl.formatMessage({ id: 'patient.error.deleteRemark' }));    
          console.error(response);
          return 'error';
        }
        toast.success(intl.formatMessage({ id: 'patient.info.remarkDeleted' }));    
        const remarksUpdate = patientRemarks.filter((remarkCurrent: Remark) => remarkCurrent.id !== remark.id);
        dispatch(setPatientRemarks(remarksUpdate));
        return 'success';
      })
      .catch((error) => {
        toast.error(intl.formatMessage({ id: 'patient.error.deleteRemark' }));    
        console.log(error.response);
        return 'error';
      });
  };

  return(
    <><Accordion.Title
      active={activeIndex === 13}
      index={13}
      onClick={(event, data) => handleClick(event, data)}
    >
      <Icon name='dropdown' />
      <FormattedMessage id='patient.remarks' />
    </Accordion.Title>
    <Accordion.Content active={activeIndex === 13}>
      <Row>
        <Col>
          <div className={cx({ Remarks: true })}>
            <h2>
              <FormattedMessage id='patient.remarks' />
            </h2>
            {patientRemarks && patientRemarks.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id='createdAt' />
                    </th>
                    <th>
                      <FormattedMessage id='user' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.message' />
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {patientRemarks?.map((remark: Remark, index: number) => (
                    <tr key={index}>
                      <td>{remark.createdAt}</td>
                      <td>{remark.username}</td>
                      <td>{remark.text}</td>
                      <td style={{ display: 'none' }}>
                        <a
                          href='#'
                          onClick={(event) => {
                            event.preventDefault();
                            editRemark(remark);
                          }}
                        >
                          <FormattedMessage id='edit' />
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          onClick={(event) => {
                            event.preventDefault();
                            // eslint-disable-next-line no-alert
                            if (window.confirm(intl.formatMessage({ id: 'patient.deleteRemarkConfirmation' }))) {
                              deleteRemark(remark);
                            }
                          }}
                        >
                          <FormattedMessage id='delete' />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {(!patientRemarks || (patientRemarks && !patientRemarks.length)) && (
              <p>
                <FormattedMessage id='patient.noRemarks' />
              </p>
            )}
            <Button onClick={() => setShowRemarkDialog(true)}>
              <FormattedMessage id='patient.addRemark' />
            </Button>
            <AddRemarkDialog
              show={showRemarkDialog}
              setShow={setShowRemarkDialog}
              onAddRemark={onAddRemark}
              clearEditedRemark={() => setRemarkToEdit(null)}
              remark={remarkToEdit}
            />
          </div>
        </Col>
      </Row>

    </Accordion.Content></>
  );
}