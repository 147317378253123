import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  Header,
  Dimmer,
  Loader,
  Button,
  Input,
} from 'semantic-ui-react';
import { fetchPatients, patientsSliceSelector } from './patientSlice';
import InfiniteScroll from 'react-infinite-scroll-component';


export default function PatientsComponent() {
  const navigate = useNavigate();
  const INITIAL_LIMIT = 50;
  const INITIAL_OFFSET = 0;
  const INITIAL_FETCH_PARAMS = () => ({
    limit: INITIAL_LIMIT,
    offset: INITIAL_OFFSET,
    order: {param: 'ninoxId', direction: 1}
  });
  const [fetchPatientsParams, setFetchPatientsParams] = useState<any>(INITIAL_FETCH_PARAMS);
  const [column, setColumn] = useState('ninoxId');
  const [directionArrow, setDirectionArrow] = useState('ascending');
  const [direction, setDirection] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const { patients, patientFound} = useSelector(patientsSliceSelector);
  const [hasMore, setHasMore] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPatients(fetchPatientsParams));
  }, [fetchPatientsParams]);

  const showPatientinfoComponent = (patientItem: any) => {
    navigate(`/patients/${patientItem.ninoxId}`);
  };

  const renderPatientsList = (patients: any) => (!patients.length
    ? (
      <Table.Row>
        <Table.Cell textAlign="center" colSpan={9}>
          <Header as="h4">No patients found!</Header>
        </Table.Cell>
      </Table.Row>
    )
    : patients.map((patientItem: any, i:number) => (
      <Table.Row key={i} positive={patientItem.isDataConfirm}>
        <Table.Cell> {patientItem.ninoxId} </Table.Cell>
        <Table.Cell> {patientItem.name} </Table.Cell>
        <Table.Cell> {patientItem.email} </Table.Cell>
        <Table.Cell width={1}>
          <Button
            size="tiny"
            onClick={() => { showPatientinfoComponent({ ...patientItem}); }}
            icon="edit" />
        </Table.Cell>
      </Table.Row>
    ))
  );

  const sortList = (param: string) => {
    setColumn(param);
    if (direction === 1) {
      setDirection(-1);
      setDirectionArrow('ascending');
    } else if (direction === -1) {
      setDirection(1);
      setDirectionArrow('descending');
    }
    const payload: any = {param, direction};
    setFetchPatientsParams({ ...fetchPatientsParams, search: searchTerm, order: payload });
  };

  const patientsListLoading = (
    <Dimmer active inverted>
      <Loader className="loading-spinner" active inline='centered'><FormattedMessage id='loading' /></Loader>
    </Dimmer>
  );

  const filterPatientsList = (patients: any, searchTerm: any) => {
    
    if (_.isEmpty(searchTerm) || searchTerm === '') {
      setFetchPatientsParams({ ...fetchPatientsParams});
      setHasMore(true);
    } else setFetchPatientsParams({ ...fetchPatientsParams, search: searchTerm });
  };

  useEffect(() => {
    _.debounce(() => filterPatientsList(patients, searchTerm), 100)();
  }, [searchTerm]);

  const onChangeSearchInput = (e: React.ChangeEvent<any>) => {
    if (e.target.value === '') {
      setSearchTerm('');
    } 
    else setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent, value: any) => {
    if (e.key === 'Enter') {
      setSearchTerm(value);
    }
  };

  const fetchMoreData = () => {
    if(fetchPatientsParams.limit > patients.length) {
      setHasMore(false);
    }else{
      setHasMore(true);
    }
    setFetchPatientsParams({...fetchPatientsParams, limit: fetchPatientsParams.limit + 50, offset: INITIAL_OFFSET});
  };

  return (
    <div className="details_container">
      <div>
        {
          (patients.length !== 0 || !patientFound) ?
            <InfiniteScroll
              dataLength={patients.length} //This is important field to render the next data
              next={fetchMoreData}
              hasMore={hasMore}
              loader={hasMore ? <h4>Loading...</h4> : ''}
              endMessage={
                <p style={{ textAlign: 'center', marginTop: 5 }}>
                  <b>{patientFound && 'Yay! You have seen it all'}</b>
                </p>
              }
            >
              <div>
                <div>
                  <div>
                    <Header sub><FormattedMessage id='patients.search' /></Header>
                    <Input icon="search" onChange={onChangeSearchInput} onKeyPress={handleKeyPress} placeholder="Search..." />
                  </div>
                  <Table celled selectable sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          sorted={column === 'ninoxId' ? directionArrow : null as any}
                          onClick={() => sortList('ninoxId')}
                        >
                      ID
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === 'name' ? directionArrow : null as any}
                          onClick={() => sortList('name')}
                        >
                          Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === 'email' ? directionArrow : null as any}
                          onClick={() => sortList('email')}
                        >
                          <FormattedMessage id='patients.email' />
                        </Table.HeaderCell>
                        <Table.HeaderCell>Edit</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
        
                    <Table.Body>
                      {renderPatientsList(patients)}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </InfiniteScroll>
            :
            patientsListLoading
        }
      </div>
    </div>
  );
}
