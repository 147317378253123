import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from './auth/authSlice';
import { setLanguage } from '../app/appSlice';
import { FormattedMessage } from 'react-intl';
import {
  Select,
  Icon,
  Menu,
  Sidebar,
} from 'semantic-ui-react';
import { setIsDisplayPatientInfo } from './patients/patientSlice';
import { setIsDisplayInfo } from './practices/practicesSlice';
import { setIsDoctorSelected } from './doctors/doctorsSlice';
import { setPatientInfoUpdate, setPatientRemarks } from './patientInfo/patientInfoSlice';
import { authSliceSelector } from './auth/authSlice';

export default function SideBarComponent() {
  const [activeItem, setactiveItem] = useState('dashboard');
  const [languageType, setLanguageType] = useState('de');
  const { auth } = useSelector(authSliceSelector);
  const dispatch = useDispatch();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const languageOptions = [
    { key: 'en', value: 'en', text: 'English' },
    { key: 'de', value: 'de', text: 'Deutsch' },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      navigate('/main');
    }
  }, [auth]);

  const handleItemClick = (name: string) => {
    clearState();

    if (name === 'dashboard') {
      navigate('/dashboard');
      setactiveItem(name);
    } else if (name === 'patients') {
      navigate('/patients');
      setactiveItem(name);
    } else if (name === 'prescriptions') {
      navigate('/prescriptions');
      setactiveItem(name);
    } else if (name === 'appointments') {
      navigate('/appointments');
      setactiveItem(name);
    } else if (name === 'doctors') {
      navigate('/doctors');
      setactiveItem(name);
    } else if (name === 'practices') {
      navigate('/practices');
      setactiveItem(name);
    }
  };

  const clearState = () => {
    dispatch(setIsDisplayPatientInfo(false));
    dispatch(setIsDisplayInfo(false));
    dispatch(setIsDoctorSelected(false));
    dispatch(setPatientRemarks([]));
    dispatch(setPatientInfoUpdate([]));
  };

  const handleLanguage = (e: any) => {
    const { innerText } = e.target;
    if (innerText === 'English') {
      setLanguageType('en');
      dispatch(setLanguage('en'));
    } else if (innerText === 'Deutsch') {
      setLanguageType('de');
      dispatch(setLanguage('de'));
    }
  };

  const onLogout = () => {
    dispatch(logOut());
  };

  return (
    <div>
      {auth && 
             <div className="navigation_container">
               <div>
                 <Sidebar
                   as={Menu}
                   animation='overlay'
                   icon='labeled'
                   inverted
                   vertical
                   visible
                   width='thin'
                 >
                   <div className="sidebar_content_container">
                     <Menu.Item>
                       <Select placeholder='Select your language' value={languageType} onChange={(e) => handleLanguage(e)} options={languageOptions} />
                     </Menu.Item>
                     <Menu.Item active={activeItem === 'dashboard'} onClick={() => handleItemClick('dashboard')}>
                       <Icon name='bars' />
                       <FormattedMessage id='admin.dashboardLink' />
                     </Menu.Item>
                     <Menu.Item active={activeItem === 'patients'} onClick={() => handleItemClick('patients')}>
                       <Icon name='users' />
                       <FormattedMessage id='admin.patientsLink' />
                     </Menu.Item>
                     <Menu.Item active={activeItem === 'prescription'} onClick={() => handleItemClick('prescriptions')}>
                       <Icon name='file alternate outline' />
                       <FormattedMessage id='admin.prescriptionLink' />
                     </Menu.Item>
                     <Menu.Item active={activeItem === 'appointments'} onClick={() => handleItemClick('appointments')}>
                       <Icon name='address book' />
                       <FormattedMessage id='appointments' />
                     </Menu.Item>
                     <Menu.Item active={activeItem === 'doctors'} onClick={() => handleItemClick('doctors')}>
                       <Icon name='user md' />
                       <FormattedMessage id='doctors' />
                     </Menu.Item>
                     <Menu.Item active={activeItem === 'practices'} onClick={() => handleItemClick('practices')}>
                       <Icon name='file alternate' />
                       <FormattedMessage id='practices' />
                     </Menu.Item>
                     <Menu.Item onClick={() => onLogout()}>
                       <Icon name='log out' />
                       <FormattedMessage id='admin.logoutLink' />
                     </Menu.Item>
                   </div>
                   <div className="sidebar_footer_container">
                     <div>
                     Copyright © {year} nowomed
                     </div>
                   </div>
                 </Sidebar>
               </div>
             </div>
      }
    </div>
  );
}
