import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
const APPOINTMENTS_SLICE = 'APPOINTMENTS';

const initialState = {
  appointments: [] as Array<any>,
  loadAppointments: false
};

export const appointmentsSlice = createSlice({
  name: APPOINTMENTS_SLICE,
  initialState,
  reducers: {
    setAppointments: (state, action) => {
      state.appointments = action.payload;
    },
    setLoadAppointments: (state, action) => {
      state.loadAppointments = action.payload;
    },
    sortAppointments: (state, action) => {
      let tempList: Array<any> = [];
      if (action.payload.direction === 1) {
        tempList = _.sortBy(state.appointments, action.payload.param).reverse();
      } else {
        tempList = _.sortBy(state.appointments, action.payload.param);
      }
      state.appointments = tempList;
    },
    fetchAppointments: () => {}, // call watcherSaga.
  },
});

export const {
  setAppointments,
  fetchAppointments,
  setLoadAppointments,
  sortAppointments,
} = appointmentsSlice.actions;

export const appointmentsSliceSelector = (state: any) => state.appointments;

export default appointmentsSlice.reducer;
  