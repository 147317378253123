import { createSlice } from '@reduxjs/toolkit';
import { PrescriptionTask } from '../../types/prescriptionTask.types';

const PRESCRIPTIONS_SLICE = 'PRESCRIPTIONS';

const initialState = {
  allPrescriptions: {open: [], closed: []},
  prescriptionsOpen: [] as any,
  prescriptionsClose: [] as any,
  isDisplayPrescriptionPage: false,
};

export const prescriptionsSlice = createSlice({
  name: PRESCRIPTIONS_SLICE,
  initialState,
  reducers: {
    setAllPrescriptions:(state, action) => {
      const allTasks = {open: action.payload.open, closed: action.payload.closed};
      state.allPrescriptions = allTasks;
    },
    setPrescriptionsOpen: (state, action) => {
      state.prescriptionsOpen = action.payload;
    },
    setPrescriptionsClose: (state, action) => {
      state.prescriptionsClose = action.payload;
    },
    setIsDisplayPrescriptionInfo: (state, action) => {
      state.isDisplayPrescriptionPage = action.payload;
    },
    filterPrescriptionList: (state, action) => {
      const tempOpenList = state.allPrescriptions.open.filter((task: PrescriptionTask) =>task.hub === parseInt(action.payload));
      const tempClosedList = state.allPrescriptions.closed.filter((task: PrescriptionTask) =>task.hub === parseInt(action.payload));
      state.prescriptionsOpen =  tempOpenList.sort((a: any, b: any) => a.date - b.date);
      state.prescriptionsClose = tempClosedList.sort((a: any, b: any) => a.date - b.date);
    },
    closePrescription: (state, action) => {
      const newClosedTask = state.prescriptionsOpen.find((task: PrescriptionTask) => task.entryId === parseInt(action.payload));
      newClosedTask.prescriptionDone = false;

      const tempOpenList = state.prescriptionsOpen.filter((task: PrescriptionTask) => task.entryId !== parseInt(action.payload));

      state.prescriptionsOpen = tempOpenList;
      state.prescriptionsClose.push(newClosedTask);
    },
    fetchPrescriptions: () => {}, // call watcherSaga.
  },
});

export const {
  setAllPrescriptions,
  setPrescriptionsOpen,
  setPrescriptionsClose,
  fetchPrescriptions,
  filterPrescriptionList,
  setIsDisplayPrescriptionInfo,
  closePrescription,
} = prescriptionsSlice.actions;

export const prescriptionsSliceSelector = (state: any) => state.prescriptions;

export default prescriptionsSlice.reducer;
  