/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Button, Icon } from 'semantic-ui-react';
import { Col, Row, Table } from 'react-bootstrap';
import { Complaints } from '../../../types/ninox.types';
import style from '../../patientInfo/EditPatient.module.scss';
import classNames from 'classnames/bind';
import { AddComplaintsDialog } from '../../../components/organisms';
import { getLocalizedDate } from '../../../utils';
import { useState } from 'react';
import { mapValuesFrom0To10 } from '../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceSelector } from '../../auth/authSlice';
import { patientInfoSliceSelector, setPatientInfoUpdate } from '../patientInfoSlice';
import { toast } from 'react-toastify';
import { deletePatientComplaints } from '../../../utils/apiCalls';

type EditPatientProps = {
  activeIndex: number,
  handleClick: (arg0: any, arg1: any) => void,
  deleteListElem: (type: string, elem: any) => void,
  };

export default function PatientComplaints({
  activeIndex,
  handleClick,
  deleteListElem}: EditPatientProps ){

  const cx = classNames.bind(style);
  const intl = useIntl();
  const dispatch = useDispatch();

  const { token } = useSelector(authSliceSelector);
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);
  const [complaintsToEdit, setComplaintsToEdit] = useState<Complaints | null>(null);
  const [showComplaintsDialog, setShowComplaintsDialog] = useState(false);

  const onAddComplaints = (complaints: Complaints): void => {
    if (complaintsToEdit) {
      // Remarks array will not be empty because we just edited a remark.
      if (patientInfoUpdate?.complaints) {
        for (let i = 0; i < patientInfoUpdate.complaints.length; i += 1) {
          if (patientInfoUpdate.complaints[i] === complaintsToEdit) {
            const complaintsUpdate = [...patientInfoUpdate.complaints];
            complaintsUpdate[i] = complaints;
            dispatch(setPatientInfoUpdate({...patientInfoUpdate, complaints: complaintsUpdate}));
          }
        }
      }
      // Reset complaints to edit after update. This will trigger the adding when the dialog is opened next time.
      setComplaintsToEdit(null);
    } else if (typeof patientInfoUpdate.complaints === 'undefined') {
      dispatch(setPatientInfoUpdate({...patientInfoUpdate, complaints: [complaints]}));
    } else {
      const complaintsUpdate = [...patientInfoUpdate.complaints];
      complaintsUpdate.push(complaints);
      dispatch(setPatientInfoUpdate({...patientInfoUpdate, complaints: complaintsUpdate}));
    }
  };

  const editComplaints = (complaints: Complaints): void => {
    // Deal with possibly not set fields in complaints
    const c = complaints;

    if (typeof c.average === 'undefined') {
      c.average = '11';
    }
    if (typeof c.maximum === 'undefined') {
      c.maximum = '11';
    }
    if (typeof c.minimal === 'undefined') {
      c.minimal = '11';
    }
    if (typeof c.maximumStrength === 'undefined') {
      c.maximumStrength = '11';
    }
    if (typeof c.current === 'undefined') {
      c.current = '11';
    }

    setComplaintsToEdit(c);
    setShowComplaintsDialog(true);
  };

  const deleteComplaints = (complaints: Complaints): void => {
    if (!patientInfoUpdate.id) {
      toast.error(intl.formatMessage({ id: 'patient.error.deleteComplaints' }));
      console.error('ID in route not set when trying to delete address');
      return;
    }
    if (!token) {
      toast.error(intl.formatMessage({ id: 'patient.error.notLoggedIn' }));
      console.error('Not logged in when trying to delete address');
      return;
    }
    // These complaints does not come from server and does not have to be deleted from server.
    if (!complaints.id) {
      return;
    }
    toast.info(intl.formatMessage({ id: 'patient.deletingComplaints' }));
    deletePatientComplaints(patientInfoUpdate.id.toString(), Number(complaints.id))
      .then(() => {
        toast.success(intl.formatMessage({ id: 'patient.complaintsDeleted' }));
      })
      .catch((error) => {
        toast.error(intl.formatMessage({ id: 'patient.error.deleteComplaints' }));
        console.error('Error while deleting complaint record', error.response);
      });
  };

  return(
    <><Accordion.Title
      active={activeIndex === 6}
      index={6}
      onClick={(event, data) => handleClick(event, data)}
    >
      <Icon name='dropdown' />
      <FormattedMessage id='patient.complaints' />
    </Accordion.Title>
    <Accordion.Content active={activeIndex === 6}>
      <Row>
        <Col>
          <div className={cx({ Complaints: true })}>
            {patientInfoUpdate.complaints && patientInfoUpdate.complaints.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id='patient.average' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.maximal' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.minimal' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.maximumStrength' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.current' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.queryDate' />
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {patientInfoUpdate.complaints?.map((complaints: Complaints, index: number) => (
                    <tr key={index}>
                      <td>{mapValuesFrom0To10(complaints.average)}</td>
                      <td>{mapValuesFrom0To10(complaints.maximum)}</td>
                      <td>{mapValuesFrom0To10(complaints.minimal)}</td>
                      <td>{mapValuesFrom0To10(complaints.maximumStrength)}</td>
                      <td>{mapValuesFrom0To10(complaints.current)}</td>
                      <td>{getLocalizedDate(intl, complaints.queryDate)}</td>
                      <td>
                        <a
                          href='#'
                          onClick={(event) => {
                            event.preventDefault();
                            editComplaints(complaints);
                          }}
                        >
                          <FormattedMessage id='edit' />
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          onClick={(event) => {
                            event.preventDefault();
                            // eslint-disable-next-line no-alert
                            if (window.confirm(intl.formatMessage({ id: 'patient.deleteComplaintsConfirmation' }))) {
                              deleteComplaints(complaints);
                              deleteListElem('complaints', complaints);
                            }
                          }}
                        >
                          <FormattedMessage id='delete' />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {(!patientInfoUpdate.complaints || (patientInfoUpdate.complaints && !patientInfoUpdate.complaints.length)) && (
              <p>
                <FormattedMessage id='patient.noComplaints' />
              </p>
            )}
            <Button onClick={()=> setShowComplaintsDialog(true)}>
              <FormattedMessage id='patient.addComplaints' />
            </Button>
            <AddComplaintsDialog
              show={showComplaintsDialog}
              setShow={setShowComplaintsDialog}
              onAddComplaints={onAddComplaints}
              complaints={complaintsToEdit}
              patientId={patientInfoUpdate?.id ? patientInfoUpdate.id.toString() : ''}
              clearEditedComplaints={() => setComplaintsToEdit(null)}
            />
          </div>
        </Col>
      </Row>
    </Accordion.Content></>
  );
}