/* eslint-disable import/prefer-default-export */
export const emptyAppointment = {
  carriedOut: '',
  comment: '',
  doctorId: 0,
  endDate: '',
  patientId: 0,
  practiceId: 0,
  startDate: '',
  type: 'online',
  appointmentType: 'firstAppointment',
};
