/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import type { AppointmentModalProps } from './Modals/AppointmentModal';
import { AppointmentModal } from './Modals/AppointmentModal';
import type { DeleteAppointmentModalProps } from './Modals/DeleteAppointmentModal';
import { DeleteAppointmentModal } from './Modals/DeleteAppointmentModal';
import type { DeleteModalProps } from './Modals/DeletePatientModal';
import { DeletePatientModal } from './Modals/DeletePatientModal';
import type { OfflinePracticeAppointmentModalProps } from './Modals/OfflinePracticeAppointmentModal';
import { OfflinePracticeAppointmentModal } from './Modals/OfflinePracticeAppointmentModal';

type ModalProps = AppointmentModalProps | DeleteModalProps | DeleteAppointmentModalProps | OfflinePracticeAppointmentModalProps;

export default function Modal(props: ModalProps): JSX.Element {
  if (props.type === 'appointment') {
    return <AppointmentModal {...props} />;
  }
  if (props.type === 'practiceAppointment') {
    return <OfflinePracticeAppointmentModal {...props} />;
  }
  if (props.type === 'deletePatient') {
    return <DeletePatientModal {...props} />;
  }
  if (props.type === 'deleteAppointment') {
    return <DeleteAppointmentModal {...props} />;
  }

  return <div>Unknown modal type</div>;
}
