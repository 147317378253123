import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Button, Icon } from 'semantic-ui-react';
import { Col, Row, Table } from 'react-bootstrap';
import { LifeQuality } from '../../../types/ninox.types';
import style from '../../patientInfo/EditPatient.module.scss';
import classNames from 'classnames/bind';
import { getLocalizedDate } from '../../../utils';
import { mapValuesFrom0To10 } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import { patientInfoSliceSelector } from '../patientInfoSlice';

type EditPatientProps = {
  activeIndex: number,
  handleClick: (arg0: any, arg1: any) => void,
  };

export default function PatientLifeQuality({
  activeIndex,
  handleClick,
}: EditPatientProps ){
  const cx = classNames.bind(style);
  const intl = useIntl();
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  return(
    <><Accordion.Title
      active={activeIndex === 9}
      index={9}
      onClick={(event, data) => handleClick(event, data)}
    >
      <Icon name='dropdown' />
      <FormattedMessage id='patient.lifeQuality' />
    </Accordion.Title>
    <Accordion.Content active={activeIndex === 9}>
      <Row>
        <Col>
          <div className={cx({ Complaints: true })}>
            {patientInfoUpdate.lifeQuality && patientInfoUpdate.lifeQuality.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id='patient.lifeQualityGeneralActivity' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.lifeQualityAppetite' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.lifeQualityConcentration' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.lifeQualityEverydayWork' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.lifeQualityJoyOfLife' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.lifeQualityMood' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.lifeQualitySexuality' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.lifeQualitySleep' />
                    </th>
                    <th>
                      <FormattedMessage id='patient.queryDate' />
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {patientInfoUpdate.lifeQuality?.map((lifeQuality: LifeQuality, index: number) => (
                    <tr key={index}>
                      <td>{mapValuesFrom0To10(lifeQuality.generalActivity)}</td>
                      <td>{mapValuesFrom0To10(lifeQuality.appetite)}</td>
                      <td>{mapValuesFrom0To10(lifeQuality.concentration)}</td>
                      <td>{mapValuesFrom0To10(lifeQuality.everydayWork)}</td>
                      <td>{mapValuesFrom0To10(lifeQuality.joyOfLife)}</td>
                      <td>{mapValuesFrom0To10(lifeQuality.mood)}</td>
                      <td>{mapValuesFrom0To10(lifeQuality.sexuality)}</td>
                      <td>{mapValuesFrom0To10(lifeQuality.sleep)}</td>
                      <td>{getLocalizedDate(intl, lifeQuality.queryDate)}</td>
                      <td>/EDIT/</td>
                      <td>/DELETE/</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {(!patientInfoUpdate.lifeQuality || (patientInfoUpdate.lifeQuality && !patientInfoUpdate.lifeQuality.length)) && (
              <p>
                <FormattedMessage id='patient.noLifeQuality' />
              </p>
            )}
            <Button
              onClick={() => true}
            >
            Todo
              {/* Missing transl. */}
              {/* <FormattedMessage id='TODO' /> */}
            </Button>
          </div>
        </Col>
      </Row>
    </Accordion.Content></>
  );
}