import { takeLatest, put, call, select } from 'redux-saga/effects';
import { setPatientDoctorsList, fetchPatientDoctorsList, fetchPatientInfo, setPatientFiles, fetchPatientRemarks, setPatientRemarks, setPatientInfoUpdate } from './patientInfoSlice';
import { fetchPatientsDoctorListCall, getPatient, getPatientFiles, getPatientRemarks } from '../../utils/apiCalls';
import { authSliceSelector, logOut, setAuthError } from '../auth/authSlice';
import { ResponseData } from '../../types/return.types';

function* fetchPatientInfoSaga(action: any) {
  try {
    const patientResponse: ResponseData = yield call(getPatient, action.payload);
    const filesResponse: ResponseData = yield call(getPatientFiles, action.payload);

    if (patientResponse.data) {
      if (patientResponse.status !== 200) {
        yield put(setAuthError(patientResponse.statusText));
      } else {
        const response = patientResponse.data;
        yield put(setPatientInfoUpdate(response));
        if(filesResponse.data){
          if(filesResponse.status === 200) {
            yield put(setPatientFiles(filesResponse.data));
          }
        }
      }
    } else {
      if (patientResponse.response.status === 403) {
        yield put (logOut());
      }
    }
  } catch (err) {
    // toast.error(err, { position: 'top-center' });
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

function* fetchPatientDoctorsListSaga(action: any) {
  try {    
    const { token } = yield select(authSliceSelector);
    const res: ResponseData = yield call(fetchPatientsDoctorListCall, token, action.payload);
      
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        const response = res.data;
        yield put(setPatientDoctorsList(response));
      }
    } else {
      // refactor the response that we received from backend
      if (res.response.status === 403) {
        yield put (logOut());
      }
    }
  } catch (err) {
    // toast.error(err, { position: 'top-center' });
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}


function* fetchPatientRemarksSaga(action: any) {
  try {
    const remarksResponse: ResponseData = yield call(getPatientRemarks, action.payload);
      
    if (remarksResponse.data) {
      if (remarksResponse.status !== 200) {
        yield put(setAuthError(remarksResponse.statusText));
      } else {
        const response = remarksResponse.data;
        yield put(setPatientRemarks(response));
      }
    } else {
      // refactor the response that we received from backend
      if (remarksResponse.response.status === 403) {
        yield put (logOut());
      }
    }
  } catch (err) {
    // toast.error(err, { position: 'top-center' });
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

export function* watchPatientInfoSaga() {
  yield takeLatest(fetchPatientInfo.type, fetchPatientInfoSaga);
  yield takeLatest(fetchPatientDoctorsList.type, fetchPatientDoctorsListSaga);
  yield takeLatest(fetchPatientRemarks.type, fetchPatientRemarksSaga);

}
