import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './DoctorDetails.module.scss';
import classNames from 'classnames/bind';
import { setdoctorFreeDates, setdoctorFreeSlots, setIsDoctorSelected } from '../doctorsSlice';
import { Button, Divider, Segment, Tab } from 'semantic-ui-react';
import DoctorInfoAppointments from './doctorInfoAppointments';
import DoctorInfoAbsences from './doctorAbsenses/doctorInfoAbsences';
import { globalSliceSelector } from '../../../app/appSlice';
const cx = classNames.bind(style);

export function DoctorInfoComponent(props: any) {
  const { selectedDoctor } = props;
  const doctorsClasses = cx({ DoctorStats: true });

  const { language } = useSelector(globalSliceSelector);

  const dispatch = useDispatch();

  // Dirty solution for translation because the Semantic menu tabs can't read the tranlation. Maybe Semantic issue.
  const menuEng = 'Available Appointments';
  const menuGer = 'Verfügbare Termine';

  const menu1Eng = 'Absences';
  const menu1Ger = 'Abwesenheiten';

  const onBackButton = () => {
    dispatch(setIsDoctorSelected(false));
    //initialize the avaliable doctor slots
    dispatch(setdoctorFreeDates([]));
    dispatch(setdoctorFreeSlots([]));
  };

  const panes = [
    { menuItem: `${language === 'en' ? menuEng : menuGer}`,
      render: () =>
        <Tab.Pane className='list-doctor-appointment'>
          <DoctorInfoAppointments
            doctorId={selectedDoctor.id}
            menuText={language === 'en' ? menuEng : menuGer}
          />
        </Tab.Pane>
    },
    { menuItem: `${language === 'en' ? menu1Eng : menu1Ger}`,
      render: () =>
        <Tab.Pane>
          <DoctorInfoAbsences
            doctorId={selectedDoctor.id}
          />
        </Tab.Pane> }
  ];

  return (
    <main className={doctorsClasses}>
      <Button onClick={() => onBackButton()} circular basic icon='arrow left' />
      <Segment raised inverted className='segment-style'>
        <article className={style.RowContainer}>
          <div className={style.DoctorTitleRowContainer}>
            <p>ID:</p>
            <p>{selectedDoctor.id}</p>
          </div>
          <div className={style.DoctorTitleRowContainer}>
            <p>Name:</p>
            <p><b>{selectedDoctor.name}</b></p>
          </div>
          <div className={style.DoctorTitleRowContainer}>
            <p>E-mail:</p>
            <p>{selectedDoctor.email}</p>
          </div>
        </article>
        <Divider />
        <article>
          <div className={style.DoctorStatsRowContainer}>
            <p><b>Auslastung 14 Tage</b></p>
            <div>
              <span
                className={
                  selectedDoctor.twoWeekStats.onlineAppointments.percentage < 80
                    ? style.progressBarLabelGreen
                    : style.progressBarLabelRed
                }
              >
                {`Online-Onboarding: ${selectedDoctor.twoWeekStats.onlineAppointments.total} verfügbar (${selectedDoctor.twoWeekStats.onlineAppointments.booked} gebucht)`}
              </span>
              <div
                className={
                  selectedDoctor.twoWeekStats.onlineAppointments.percentage < 80 ? style.resourcesBarGreen : style.resourcesBarRed
                }
              >
                <div
                  className={
                    selectedDoctor.twoWeekStats.onlineAppointments.percentage < 80
                      ? style.resourcesBarProgressGreen
                      : style.resourcesBarProgressRed
                  }
                  style={{ width: `${selectedDoctor.twoWeekStats.onlineAppointments.percentage}%` }}
                ></div>
              </div>
            </div>
            <div>
              <span
                className={
                  selectedDoctor.twoWeekStats.followUpAppointments.percentage < 80 ? style.progressBarLabel : style.progressBarLabelRed
                }
              >{`Folgetermine: ${selectedDoctor.twoWeekStats.followUpAppointments.total} verfügbar (${selectedDoctor.twoWeekStats.followUpAppointments.booked} gebucht)`}</span>
              <div
                className={
                  selectedDoctor.twoWeekStats.followUpAppointments.percentage < 80 ? style.resourcesBar : style.resourcesBarRed
                }
              >
                <div
                  className={
                    selectedDoctor.twoWeekStats.followUpAppointments.percentage < 80
                      ? style.resourcesBarProgress
                      : style.resourcesBarProgressRed
                  }
                  style={{ width: `${selectedDoctor.twoWeekStats.followUpAppointments.percentage}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className={style.DoctorStatsRowContainer}>
            <p><b>Auslastung total</b></p>
            <div>
              <span
                className={
                  selectedDoctor.totalStats.onlineAppointments.percentage < 80
                    ? style.progressBarLabelGreen
                    : style.progressBarLabelRed
                }
              >
                {`Online-Onboarding: ${selectedDoctor.totalStats.onlineAppointments.total} verfügbar (${selectedDoctor.totalStats.onlineAppointments.booked} gebucht)`}
              </span>
              <div
                className={
                  selectedDoctor.totalStats.onlineAppointments.percentage < 80 ? style.resourcesBarGreen : style.resourcesBarRed
                }
              >
                <div
                  className={
                    selectedDoctor.totalStats.onlineAppointments.percentage < 80
                      ? style.resourcesBarProgressGreen
                      : style.resourcesBarProgressRed
                  }
                  style={{ width: `${selectedDoctor.totalStats.onlineAppointments.percentage}%` }}
                ></div>
              </div>
            </div>
            <div>
              <span
                className={
                  selectedDoctor.totalStats.followUpAppointments.percentage < 80
                    ? style.progressBarLabel
                    : style.progressBarLabelRed
                }
              >{`Folgetermine: ${selectedDoctor.totalStats.followUpAppointments.total} verfügbar (${selectedDoctor.totalStats.followUpAppointments.booked} gebucht)`}</span>
              <div
                className={
                  selectedDoctor.totalStats.followUpAppointments.percentage < 80 ? style.resourcesBar : style.resourcesBarRed
                }
              >
                <div
                  className={
                    selectedDoctor.totalStats.followUpAppointments.percentage < 80
                      ? style.resourcesBarProgress
                      : style.resourcesBarProgressRed
                  }
                  style={{ width: `${selectedDoctor.totalStats.followUpAppointments.percentage}%` }}
                ></div>
              </div>
            </div>
          </div>
        </article>
      </Segment>
      <Tab panes={panes} />
    </main>
  );

}
