/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import FormDialog from '../../FormDialog/FormDialog';
import { setPatientInfoUpdate, setPatientRemarks } from '../../../../features/patientInfo/patientInfoSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deletePatientCall } from '../../../../utils/apiCalls';

export type DeleteModalProps = {
  type: 'deletePatient';
  show: boolean;
  setShow: (show: boolean) => void;
  patientId: number;
};

export function DeletePatientModal(props: DeleteModalProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deletePatient = () => {
    deletePatientCall(props.patientId).then(() => {
      props.setShow(false);
      dispatch(setPatientRemarks([]));
      dispatch(setPatientInfoUpdate([]));
      navigate('/patients');
    });
  };

  return (
    <FormDialog
      title={'Patient löschen'}
      onSave={() => deletePatient()}
      onClose={() => props.setShow(false)}
      setShow={() => props.setShow(false)}
      show={props.show}
      textSave={'Löschen'}
      variantSave={'danger'}
    >
      Soll der Patient wirklich gelöscht werden?
    </FormDialog>
  );
}
