/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/* eslint-disable import/extensions */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '../../atoms';

type FormDialogProps = {
  className?: Record<string, boolean>;
  children?: React.ReactNode;
  title?: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onSave: () => void;
  onClose?: () => void;
  textSave?: string;
  variantSave?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
};

export default function FormDialog(props: FormDialogProps): JSX.Element {
  const onClickCancel = (/* event: React.MouseEvent<HTMLButtonElement> */) => {
    if (props.onClose) {
      props.onClose();
    }

    props.setShow(false);
  };

  const onClickSave = () => {
    props.onSave();
    // props.setShow(false);
  };

  return (
    <Modal show={props.show} animation={true} centered onHide={onClickCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClickCancel}>
          Cancel
        </Button>
        <Button variant={props.variantSave ? props.variantSave : 'primary'} onClick={onClickSave}>
          {props.textSave ? props.textSave : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
