/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { API_URL } from '../constants';
import type { Appointment } from '../types/ninox.types';
import type { AxiosReturn } from '../types/return.types';
import { getAPIToken } from '../utils';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Authorization = `Bearer ${getAPIToken()}`;

export default class AppointmentService {
  static async createAppointment(appointment: Appointment): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .post('/appointment', appointment)
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }
  // deprecated
  static async getAllAppointments(): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .get('/appointment')
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async getAllAppointmentsByDoctor(id: number): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .get(`/appointment/doctor/${id}`)
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async getAllAppointmentsByPatient(id: number): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .get(`/appointment/patient/${id}`)
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async getAppointment(id: number): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .get(`/appointment/${id}`)
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async updateAppointment(id: number, appointment: Appointment): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .put(`/appointment/${id}`, appointment)
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async deleteAppointment(id: number): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .delete(`/appointment/${id}`)
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }
}
