/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, authSliceSelector, setAuthError } from './authSlice';
import {
  Button, Form, Image, Icon, Segment, Loader
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import FooterComponent from './Footer';

const LoginComponent = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector(authSliceSelector);
  const { authError } = state;

  useEffect(() => {
    if (state.auth) {
      <Navigate to='/dashboard'  />;
    }
  }, [state.auth]);

  useEffect(() => {
    if (authError) {
      setIsLoading(false);
      toast.error(authError);
      dispatch(setAuthError(null));
      setIsLoading(false);
    }
  }, [dispatch, authError]);

  const handleSubmit = () => {
    setIsLoading(true);
    const user: any = {
      username: username.toLowerCase(),
      password,
    };
    dispatch(login(user));
  };

  const OnChangeUsername = (e: React.ChangeEvent<any>) => {
    setUsername(e.target.value);
  };

  const OnChangePassword = (e: React.ChangeEvent<any>) => {
    setPassword(e.target.value);
  };

  const togglePassView = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login_page_container">
      <div className="login_form_container">
        <div className="login_form_wrapper">
          <div>
            <Segment raised>
              <Image style={{margin: 'auto'}} src='https://www.nowomed.com/wp-content/uploads/2021/10/logo.svg' size='small' />
              <Form onSubmit={handleSubmit} className="login_form">
                <Form.Input
                  icon="user"
                  iconPosition="left"
                  label="Email"
                  type="email"
                  name="username"
                  onChange={OnChangeUsername}
                  value={username}
                />
                <Form.Input
                  icon={<Icon name={showPassword ? 'eye slash' : 'eye'} link onClick={togglePassView} />}
                  iconPosition="left"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  onChange={OnChangePassword}
                  value={password}
                />
                <div style={{textAlign: 'center'}}>
                  {isLoading
                    ? (
                      <Loader
                        active inline='centered'
                      />
                    )
                    : (
                      <Button size="big" type="submit" content="Login" className='loginbtn' />
                    )}
                </div>
              </Form>
            </Segment>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};
export default LoginComponent;
