import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import type {Patient} from '../../types/ninox.types';
import type { SelectOption } from '../../components/atoms/Input/Input';
import { Modal } from '../../components/molecules';
import { Accordion, Loader } from 'semantic-ui-react';
import style from './EditPatient.module.scss';
import { useDispatch } from 'react-redux';
import { setUpdatedPatient } from '../patients/patientSlice';
import { Button } from 'semantic-ui-react';
import { authSliceSelector } from '../auth/authSlice';
import PatientDoctor from './accordionComponents/patientDoctor.component';
import PatientData from './accordionComponents/patientData.component';
import PatientAddress from './accordionComponents/patientAddress.component';
import PatientAppointment from './accordionComponents/patientAppointment.component';
// import PatientFurtherQuestions from './accordionComponents/patientFurtherQuestions.component';
import PatientFirstPresentation from './accordionComponents/patientFirstPresentation.component';
import PatientComplaints from './accordionComponents/patientComplaints.components';
// import PatientPainLocation from './accordionComponents/patientPainLocation.component';
import PatientSecondaryDiagnosis from './accordionComponents/patientSecondaryDiagnosis.component';
import PatientLifeQuality from './accordionComponents/patientLifeQuality.component';
import PatientMedication from './accordionComponents/patientMedication.component';
import PatientProgress from './accordionComponents/patientProgress.component';
import PatientFiles from './accordionComponents/patientFiles.component';
import PatientRemarks from './accordionComponents/patientRemarks.component';
import { fetchPatientDoctorsList, fetchPatientInfo, patientInfoSliceSelector, setPatientInfoUpdate, setPatientRemarks } from './patientInfoSlice';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { acceptPatientData, assignDoctors, editPatientInfo } from '../../utils/apiCalls';

const cx = classNames.bind(style);

export default function PatientInfoComponent(props: any) {
  const navigate = useNavigate();
  const { ninoxId } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const editPatientClasses = cx({ EditPatient: true });

  const { token } = useSelector(authSliceSelector);
  const { patientDoctorsList, patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  const [activeIndex, setActiveIndex] = useState(0);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dataLoading = (
    <Loader className="loading-spinner" active inline='centered'><FormattedMessage id='loading' /></Loader>
  );

  useEffect(() => {
    if (ninoxId) {
      if(_.isEmpty(patientInfoUpdate) || ninoxId !== patientInfoUpdate.id){
        const payload: any= ninoxId ;
        dispatch(fetchPatientInfo(payload));
        dispatch(fetchPatientDoctorsList(payload));
      }
    }
  }, [ninoxId]);

  /**
   * Handles all change events for form fields. A field has a name corresponding to the key in the patient array. E.g. the value
   * patient.firstPresentation.locationOfPain.head has the field name "firstPresentation.locactionOfPain.head". Up to three levels
   * can be handled.
   * @param event The change event for the form field.
   */
  const onChangeFormField = (event: React.ChangeEvent<any>): void => {
    const {
      name, value, checked, type, min, max,
    } = event.target;

    let valueToSet: boolean | string | number;

    if (type === 'checkbox') {
      valueToSet = checked;
    } else {
      valueToSet = value;
    }

    if (type === 'number') {
      valueToSet = Math.max(Number(min), Math.min(Number(max), Number(value)));
    }

    const dotMatch = name.match(/\./g);
    let dotCount = 0;

    if (dotMatch) {
      dotCount = name.match(/\./g).length;
    }

    if (dotCount === 1) {
      const firstLevel: string = name.substr(0, name.indexOf('.'));
      const secondLevel: string = name.substr(name.indexOf('.') + 1);
      const dataFirstLevel = patientInfoUpdate[firstLevel as keyof Patient] || {};
      const updatePatient = { ...patientInfoUpdate, [firstLevel]: { ...dataFirstLevel, [secondLevel]: valueToSet } };
      dispatch(setPatientInfoUpdate(updatePatient));
    } else if (dotCount === 2) {
      const levels: string[] = name.split('.');
      const dataFirstLevel = patientInfoUpdate[levels[0] as keyof Patient] || {};
      const dataSecondLevel = (dataFirstLevel as Record<string, any>)[levels[1]] || {};
      const updatePatient = {
        ...patientInfoUpdate,
        [levels[0]]: { ...dataFirstLevel, [levels[1]]: { ...dataSecondLevel, [levels[2]]: valueToSet } },
      };
      dispatch(setPatientInfoUpdate(updatePatient));
    } else {
      const updatePatient = { ...patientInfoUpdate, [name]: valueToSet };
      dispatch(setPatientInfoUpdate(updatePatient));
    }
  };

  /**
   * Handles all change events for multiselect form fields. A field has a name corresponding to the key in the patient array. E.g.
   * the value patient.firstPresentation.locationOfPain.head has the field name "firstPresentation.locactionOfPain.head". Up to
   * three levels can be handled. The event handler is different, since the React Select module is used for these Multiselects.
   * @param name The name of the multiselect field.
   * @param options The selected Options
   */
  const onMultiSelectChange = (name: string, options: readonly SelectOption[]): void => {
    const path = name.split('.');
    const pathCount = path.length;
    let stringToSet = '';

    for (let i = 0; i < options.length; i += 1) {
      if (i !== 0) {
        stringToSet += ', ';
      }
      stringToSet += options[i].value;
    }

    if (pathCount === 1) {

      const updatePatient = { ...patientInfoUpdate, [name]: stringToSet };
      dispatch(setPatientInfoUpdate(updatePatient));
    } else if (pathCount === 2) {

      const firstLevelData = (patientInfoUpdate as Record<string, any>)[path[0]];
      const updatePatient =  { ...patientInfoUpdate, [path[0]]: { ...firstLevelData, [path[1]]: stringToSet } };
      dispatch(setPatientInfoUpdate(updatePatient));
    } else if (pathCount === 3) {

      const firstLevelData = (patientInfoUpdate as Record<string, any>)[path[0]];
      const secondLevelData = (firstLevelData as Record<string, any>)[path[1]];
      const updatePatient =  {
        ...patientInfoUpdate,
        [path[0]]: { ...firstLevelData, [path[1]]: { ...secondLevelData, [path[2]]: stringToSet } },
      };
      dispatch(setPatientInfoUpdate(updatePatient));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deleteListElem = (type: string, elem: any): void => {
    const arrayToFilter = patientInfoUpdate[type as keyof Patient];

    if (typeof arrayToFilter !== 'undefined') {
      const newArray = (arrayToFilter as Array<any>).filter((obj: any) => obj !== elem);
      const updatePatient = { ...patientInfoUpdate, [type]: newArray };
      dispatch(setPatientInfoUpdate(updatePatient));
    }
  };

  const savePatient = (/* event: React.MouseEvent<HTMLButtonElement> */): void => {
    if (token) {
      if (patientInfoUpdate.id && patientInfoUpdate.id !== 0) {
        toast.info(intl.formatMessage({ id: 'patient.info.savingPatient' }));
        // This is the data which will be sent to backend. Some of the data from the patient object is excluded.
        const {
          progress, file, medication, followUpPrescriptionRequest, followUpPrescriptionQuestion, doctorPatientRelation, ...data
        } = patientInfoUpdate;
        // the medication, followUpPrescriptionRequest, followUpPrescriptionQuestion keys are added above for debugging

        editPatientInfo(patientInfoUpdate.id, data)
          .then((response) => {
            if (response.data === 'Update success' && response.status === 200) {
              dispatch(setUpdatedPatient(patientInfoUpdate));
              // dispatch(fetchPatientDoctorsList(patientInfoUpdate.id));
            }
            assignDoctors(patientInfoUpdate.id, patientDoctorsList).then((response) => {
              if (response.error) {
                toast.error(intl.formatMessage({ id: 'patient.error.savePatient' }));
                console.error({ response });
                return;
              }
              toast.success(intl.formatMessage({ id: 'patient.info.patientSaved' }));
            });
          })
          .catch((error) => {
            toast.error(intl.formatMessage({ id: 'patient.error.savePatient' }));
            console.error(error.response);
          });
      } else {
        console.log('new patient should be created here');
      }
    } else {
      toast.error(intl.formatMessage({ id: 'patient.error.notLoggedIn' }));
    }
  };

  const acceptData = (): void => {
    if (token) {
      if (patientInfoUpdate.id && patientInfoUpdate.id !== 0) {
        toast.info(intl.formatMessage({ id: 'patient.info.acceptingData' }));

        acceptPatientData(patientInfoUpdate.id)
          .then(() => {
            toast.success(intl.formatMessage({ id: 'patient.info.acceptedData' }));
            const updatePatient = { ...patientInfoUpdate, patientDataApproved: true };
            dispatch(setPatientInfoUpdate(updatePatient));
          })
          .catch((error) => {
            toast.error(intl.formatMessage({ id: 'patient.error.acceptData' }));
            console.error(error.response);
          });
      } else {
        console.log('new patient should be created here');
      }
    } else {
      toast.error(intl.formatMessage({ id: 'patient.error.notLoggedIn' }));
    }
  };

  const handleClick = (event: any, titleProps: any) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const onLeave = () => {
    navigate(-1);
    dispatch(setPatientRemarks([]));
    dispatch(setPatientInfoUpdate([]));
  };

  return (
    <div className="details_container">
      {patientInfoUpdate.length !== 0 ?
        <><div className={editPatientClasses}>
          <div className={cx({ TopBar: true })}>
            <div className={cx({ PatientData: true })}>
              <span>{patientInfoUpdate.lastname}, </span>
              <span>{patientInfoUpdate.firstname}</span>
            </div>
            <div className={cx({ SaveButton: true })}>
              <Button color='blue' onClick={() => setShowAppointmentModal(true)}>
                <FormattedMessage id='createAppointment' />
              </Button>
              <Modal
                type='appointment'
                show={showAppointmentModal}
                setShow={setShowAppointmentModal}
                appointment={null}
                patientId={patientInfoUpdate.id}
                doctors={patientDoctorsList} />
              <Button color='red' onClick={() => setShowDeleteModal(true)}>
                <FormattedMessage id='deletePatient' />
              </Button>
              <Modal type='deletePatient' show={showDeleteModal} setShow={setShowDeleteModal} patientId={patientInfoUpdate.id} />
              {!patientInfoUpdate.patientDataApproved && (
                <Button color='blue' onClick={acceptData}>
                  <FormattedMessage id='patient.acceptData' />
                </Button>
              )}
              <Button color='green' onClick={savePatient}>
                <FormattedMessage id='patient.savePatientData' />
              </Button>
            </div>
          </div>
        </div>
        <Button onClick={() => onLeave()} circular basic icon='arrow left' style={{ marginTop: 50, marginBottom: 15 }} />

        {/* We do not currently need the PatientFurtherQuestions & PatientPainLocation but we might want them in the future */}
        <div className={cx({ Spacer: true })}>
          <Accordion fluid styled>
            <PatientDoctor activeIndex={activeIndex} handleClick={handleClick} patientId={patientInfoUpdate.id} />
            <PatientAppointment activeIndex={activeIndex} handleClick={handleClick} />
            <PatientData activeIndex={activeIndex} handleClick={handleClick} onChangeFormField={onChangeFormField} />
            <PatientAddress activeIndex={activeIndex} handleClick={handleClick} deleteListElem={deleteListElem} />
            <PatientRemarks activeIndex={activeIndex} handleClick={handleClick} />
            <PatientFiles activeIndex={activeIndex} handleClick={handleClick} />
            <PatientProgress activeIndex={activeIndex} handleClick={handleClick} onChangeFormField={onChangeFormField} />
            {/* <PatientFurtherQuestions activeIndex={activeIndex} handleClick={handleClick} onChangeFormField={onChangeFormField} /> */}
            <PatientFirstPresentation activeIndex={activeIndex} handleClick={handleClick} onChangeFormField={onChangeFormField} onMultiSelectChange={onMultiSelectChange} />
            <PatientComplaints activeIndex={activeIndex} handleClick={handleClick} deleteListElem={deleteListElem} />
            {/* <PatientPainLocation activeIndex={activeIndex} handleClick={handleClick} onChangeFormField={onChangeFormField} /> */}
            <PatientSecondaryDiagnosis activeIndex={activeIndex} handleClick={handleClick} onChangeFormField={onChangeFormField} onMultiSelectChange={onMultiSelectChange} />
            <PatientLifeQuality activeIndex={activeIndex} handleClick={handleClick} />
            <PatientMedication activeIndex={activeIndex} handleClick={handleClick} onChangeFormField={onChangeFormField} />
          </Accordion>
        </div></>
        : dataLoading
      }
    </div>
  );
}
