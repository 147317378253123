import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import authReducer from '../features/auth/authSlice';
import globalReducer from '../app/appSlice';
import appointmentsReducer from '../features/appointments/appointmentsSlice';
import patientsReducer from '../features/patients/patientSlice';
import doctosReducer from '../features/doctors/doctorsSlice';
import practicesReducer from '../features/practices/practicesSlice';
import prescriptionsReducer from '../features/prescriptions/prescriptionsSlice';
import patientInfoReducer from '../features/patientInfo/patientInfoSlice';
import rootSaga from './saga';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create the store middlewares array, to include saga middleware
// const middleware:  (getDefaultMiddleware: any) => getDefaultMiddleware({
//   serializableCheck: false,
//   thunk: false
// }).concat(logger);


// const middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//   serializableCheck: false,
//   thunk: false
// }).concat(sagaMiddleware)
// const middleware: (getDefaultMiddleware) => [
//   ...getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(logger),
//   sagaMiddleware,
// ],
// const devMode = process.env.NODE_ENV === 'development';

// if (devMode) {
//   // If in devMode, add redux logging
//   middleware.push(logger);
// }

// Include all reducers to store
const reducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  appointments: appointmentsReducer,
  patients: patientsReducer,
  doctors: doctosReducer,
  practices: practicesReducer,
  prescriptions: prescriptionsReducer,
  patientInfo: patientInfoReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(logger),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
  // devTools: devMode
});

// Start sagas
sagaMiddleware.run(rootSaga);
