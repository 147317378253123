import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Appointment, Practice } from '../../../../types/ninox.types';
import FormDialog from '../../FormDialog/FormDialog';
import FormField from '../../FormField/FormField';
import { createOfflinePracticeAppointment } from '../../../../utils/apiCalls';

export type OfflinePracticeAppointmentModalProps = {
    onSubmit(updatedAppointment: { patient: { firstname: string; lastname: string; birthday: string; email: string; mobile: string; }; id?: string; comment: string; carriedOut: string; type: string; appointmentType: string; patientId: number; patientName?: string; doctorId: number; doctorName?: string; practiceId: number; startDate: string; endDate: string; practice?: Practice; }): unknown;
    type: 'practiceAppointment';
    show: boolean;
    appointment: Appointment | null;
    setShow: (show: boolean) => void;
    reloadSlots: () => void;
};

export function OfflinePracticeAppointmentModal(props: OfflinePracticeAppointmentModalProps): JSX.Element {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [birthday, setBirthday] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [street, setStreet] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [city, setCity] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const intl = useIntl();

  const handleSubmit = async () => {
    // Ensure all required fields are filled out and set specific error messages
    const requiredFields = [
      { value: firstname, message: 'Vorname ist erforderlich.' },
      { value: lastname, message: 'Nachname ist erforderlich.' },
      { value: birthday, message: 'Geburtstag ist erforderlich.' },
      { value: email, message: 'E-Mail ist erforderlich.' },
      { value: mobile, message: 'Telefonnummer ist erforderlich.' },
      { value: street, message: 'Straße ist erforderlich.' },
      { value: houseNumber, message: 'Hausnummer ist erforderlich.' },
      { value: city, message: 'Ort ist erforderlich.' },
      { value: zipcode, message: 'Postleitzahl ist erforderlich.' },
    ];
    
    for (const field of requiredFields) {
      if (!field.value) {
        setErrorMessage(field.message);
        return;
      }
    }
    
    // If appointment details are not available, set an error message
    if (!props.appointment) {
      setErrorMessage('Keine Termininformation verfügbar.');
      return;
    }

    setIsSubmitting(true);

    // Construct updated appointment with patient details
    const updatedAppointment = {
      ...props.appointment,
      patient: {
        firstname,
        lastname,
        birthday,
        email,
        mobile,
        street,
        houseNumber,
        city,
        zipcode,
      },
    };

    const token = String(localStorage.getItem('Token'));

    try {
      const response = await createOfflinePracticeAppointment(token, updatedAppointment);

      if (response.status !== 200) {
        const responseMessage = response.response?.data?.message || 'Ein unbekannter Fehler ist aufgetreten.';

        // Set error message for Appointment collision or other errors
        if (responseMessage.includes('Appointment collision')) {
          setErrorMessage('Es gibt einen Terminkonflikt. Bitte wählen Sie eine andere Zeit.');
        } else {
          setErrorMessage(responseMessage);
        }

        setIsSubmitting(false);
        return; // Stop further execution to prevent appointment creation
      }

      // If appointment is successfully created, close modal and reload slots if needed
      props.setShow(false);
      if (props.reloadSlots) {
        props.reloadSlots();
      }
    } catch (error) {
      console.error('Fehler beim Erstellen des Termins:', error);
      setErrorMessage('Netzwerkfehler: Bitte versuchen Sie es später noch einmal.');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="offline-appointment-modal">
      <FormDialog
        title={intl.formatMessage({ id: 'createAppointment' })}
        onSave={handleSubmit}
        textSave={isSubmitting ? 'Loading..' : 'Save'}
        variantSave={isSubmitting ? 'light' : 'primary'}
        onClose={() => props.setShow(false)}
        setShow={() => props.setShow(false)}
        show={props.show}
      >
        <p>
          bei <strong> {(props.appointment as any)?.doctor?.doctorName}</strong> um{' '}
          <strong>
            {new Date((props.appointment as any)?.start).toLocaleTimeString('de-DE', {
              hour: '2-digit',
              minute: '2-digit',
            })}{' '}
                      Uhr.
          </strong>
        </p>
        <FormField
          type="text"
          name="firstname"
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        >
          <FormattedMessage id="Vorname" />
        </FormField>
        <FormField
          type="text"
          name="lastname"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        >
          <FormattedMessage id="Nachname" />
        </FormField>
        <FormField
          type="date"
          name="birthday"
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
        >
          <FormattedMessage id="Geburtstag" />
        </FormField>
        <FormField
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        >
          <FormattedMessage id="Email" />
        </FormField>
        <FormField
          type="tel"
          name="mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        >
          <FormattedMessage id="Telefon" />
        </FormField>
        <FormField
          type="text"
          name="street"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
        >
          <FormattedMessage id="Straße" />
        </FormField>
        <FormField
          type="text"
          name="houseNumber"
          value={houseNumber}
          onChange={(e) => setHouseNumber(e.target.value)}
        >
          <FormattedMessage id="Hausnummer" />
        </FormField>
        <FormField
          type="text"
          name="city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        >
          <FormattedMessage id="Stadt" />
        </FormField>
        <FormField
          type="text"
          name="zipcode"
          value={zipcode}
          onChange={(e) => setZipcode(e.target.value)}
        >
          <FormattedMessage id="Postleitzahl" />
        </FormField>
        {/* Error message display */}
        {errorMessage && (
          <div className="error-message" style={{ color: 'red', marginTop: '10px' }}>
            {errorMessage}
          </div>
        )}
      </FormDialog>
    </div>
  );
}

export default OfflinePracticeAppointmentModal;