// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import classNames from 'classnames/bind';
import style from './Button.module.scss';

const cx = classNames.bind(style);

type ButtonProps = {
  children: React.ReactNode;
  variant?: string;
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function Button(props: ButtonProps): JSX.Element {
  const buttonClasses = cx({ Button: true });

  return (
    // eslint-disable-next-line max-len
    <BootstrapButton variant={props.variant} className={buttonClasses} onClick={props.onClick} id={props.id}>
      {props.children}
    </BootstrapButton>
  );
}
