import { Header, Segment, Icon } from 'semantic-ui-react';
import nowomedLogo from '../../assets/favicon.png';



export default function DashboardComponent() {

  return (
    <Segment placeholder>
      <Header icon>
        <Icon name='chart pie' />
        <img src={nowomedLogo} />
         nowomed Dashboard Coming Soon
      </Header>
    </Segment>
  );
}