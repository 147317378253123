import { FormattedMessage } from 'react-intl';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col, Row } from 'react-bootstrap';
import { FormField } from '../../../components/molecules';
import { SelectOption } from '../../../components/atoms/Input/Input';
import { getFieldValue, getLocalizedChoices, isInMultiSelect } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import { patientInfoSliceSelector } from '../patientInfoSlice';

type EditPatientProps = {
  activeIndex: number,
  onChangeFormField: (event: React.ChangeEvent<any>) => void,
  handleClick: (arg0: any, arg1: any) => void,
  onMultiSelectChange: (name: string, options: readonly SelectOption[]) => void,
  };

export default function PatientFirstPresentation({activeIndex, handleClick, onChangeFormField, onMultiSelectChange }: EditPatientProps ){
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  return(
    <><Accordion.Title
      active={activeIndex === 5}
      index={5}
      onClick={(event, data) => handleClick(event, data)}
    >
      <Icon name='dropdown' />
      <FormattedMessage id='patient.firstPresentation' />
    </Accordion.Title>
    <Accordion.Content active={activeIndex === 5}>
      <Row>
        <Col lg={6}>
          <FormField
            type='multiselect'
            name='firstPresentation.mainDisease'
            value={getFieldValue('string', patientInfoUpdate.firstPresentation?.mainDisease)}
            choices={getLocalizedChoices([
              ['chronicPains', 'Chronic pains'],
              ['sleepDisorders', 'Sleep disorders'],
              ['migraine', 'Migraine'],
              ['adhs', 'ADHS'],
              ['depression', 'Depression'],
              ['otherComplaints', 'Other complaints'],
            ])}
            onMultiSelectChange={onMultiSelectChange}
          >
            <FormattedMessage id='patient.mainDisease' />
          </FormField>
        </Col>
        <Col>
          <FormField
            type='text'
            name='firstPresentation.designation'
            value={getFieldValue('string', patientInfoUpdate.firstPresentation?.designation)}
            onChange={onChangeFormField}
          >
            <FormattedMessage id='patient.designation' />
          </FormField>
        </Col>
        <Col>
          <FormField
            type='checkbox'
            value='Has main diagnosis?'
            name='firstPresentation.hasMainDiagnosis'
            checked={getFieldValue('boolean', patientInfoUpdate.firstPresentation?.hasMainDiagnosis)}
            onChange={onChangeFormField}
          >
            <FormattedMessage id='patient.hasMainDiagnosis' />
          </FormField>
        </Col>
        <Col>
          <FormField
            type='checkbox'
            value='Relatives?'
            name='firstPresentation.relatives'
            checked={getFieldValue('boolean', patientInfoUpdate.firstPresentation?.relatives)}
            onChange={onChangeFormField}
          >
            <FormattedMessage id='patient.relatives' />
          </FormField>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormField
            type='date'
            name='firstPresentation.sinceDisease'
            value={getFieldValue('string', patientInfoUpdate.firstPresentation?.sinceDisease)}
            onChange={onChangeFormField}
          >
            <FormattedMessage id='patient.diseaseSince' />
          </FormField>
          <FormField
            type='select'
            name='firstPresentation.frequentComplaints'
            value={getFieldValue('string', patientInfoUpdate.firstPresentation?.frequentComplaints)}
            onChange={onChangeFormField}
            choices={getLocalizedChoices([
              ['', 'not set'],
              ['permanent', 'permanent'],
              ['daily', 'daily'],
              ['weekly', 'weekly'],
              ['monthly', 'monthly'],
              ['lessFrequently', 'less frequently'],
            ])}
          >
            <FormattedMessage id='patient.complaintFrequency' />
          </FormField>
        </Col>
        <Col>
          <FormField
            type='multiselect'
            name='firstPresentation.previousDoctors'
            value={getFieldValue('string', patientInfoUpdate.firstPresentation?.previousDoctors)}
            choices={getLocalizedChoices([
              ['familyDoctor', 'Family doctor'],
              ['alternativePractitioner', 'Alternative practitioner'],
              ['orthopedist', 'Orthopedist'],
              ['neurologist', 'Neurologist'],
              ['gynecologist', 'Gynecologist'],
              ['eyeSpecialist', 'Eye specialist'],
              ['psychotherapist', 'Psychotherapist'],
              ['internist', 'Internist'],
              ['anesthesiologist', 'Anesthesiologist'],
              ['dermatologist', 'Dermatologist'],
              ['otorhinolaryngologist', 'Otorhinolaryngologist'],
              ['chiropractor', 'Chiropractor'],
              ['surgeon', 'Surgeon'],
              ['radiologist', 'Radiologist'],
              ['psychiatrist', 'Psychiatrist'],
              ['urologist', 'Urologist'],
              ['other', 'Other'],
            ])}
            onMultiSelectChange={onMultiSelectChange}
          >
            <FormattedMessage id='patient.previousDoctors' />
          </FormField>
          {isInMultiSelect(patientInfoUpdate.firstPresentation?.previousDoctors, 'other') && (
            <FormField
              type='input'
              name='firstPresentation.previousDoctorsOther'
              value={getFieldValue('string', patientInfoUpdate.firstPresentation?.previousDoctorsOther)}
              onChange={onChangeFormField}
            ></FormField>
          )}
          <FormField
            type='multiselect'
            name='therapy.otherTreatmentMethods'
            value={getFieldValue('string', patientInfoUpdate.therapy?.otherTreatmentMethods)}
            onMultiSelectChange={onMultiSelectChange}
            choices={getLocalizedChoices([
              ['none', 'None'],
              ['injection', 'Injections'],
              ['acupuncture', 'Acupuncture'],
              ['meditation', 'Meditation'],
              ['cure', 'Cure'],
              ['baths', 'Baths'],
              ['physiotherapy', 'Physiotherapy'],
              ['psychotherapy', 'Psychotherapy'],
              ['hypnosis', 'Hypnosis'],
              ['massages', 'Massages'],
              ['homeopathy', 'Homeopathy'],
              ['other', 'Other'],
            ])}
          >
            <FormattedMessage id='patient.otherTreatmentMethods' />
          </FormField>
          {isInMultiSelect(patientInfoUpdate.therapy?.otherTreatmentMethods, 'other') && (
            <FormField
              type='input'
              name='therapy.otherTreatmentMethodsOther'
              value={getFieldValue('string', patientInfoUpdate.therapy?.otherTreatmentMethodsOther)}
              onChange={onChangeFormField}
            ></FormField>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <FormField
            type='select'
            name='additionalQuestions.pains'
            value={getFieldValue('string', patientInfoUpdate.additionalQuestions?.pains)}
            onChange={onChangeFormField}
            choices={[
              ['11', '0'],
              ['1', '1'],
              ['2', '2'],
              ['3', '3'],
              ['4', '4'],
              ['5', '5'],
              ['6', '6'],
              ['7', '7'],
              ['8', '8'],
              ['9', '9'],
              ['10', '10'],
            ]}
          >
            <FormattedMessage id='patient.pains' />
          </FormField>
          <FormField
            type='multiselect'
            name='additionalQuestions.previousTreatments'
            value={getFieldValue('string', patientInfoUpdate.additionalQuestions?.previousTreatments)}
            onMultiSelectChange={onMultiSelectChange}
            choices={getLocalizedChoices([
              ['physiotherapy', 'Physiotherapy'],
              ['medication', 'Medication'],
              ['psychotherapy', 'Psychotherapy'],
              ['massages', 'Massages'],
              ['further', 'Further'],
            ])}
          >
            <FormattedMessage id='patient.previousTreatments' />
          </FormField>
          {isInMultiSelect(patientInfoUpdate.additionalQuestions?.previousTreatments, 'further') && (
            <FormField
              type='input'
              name='additionalQuestions.previousTreatmentsFurther'
              value={getFieldValue('string', patientInfoUpdate.additionalQuestions?.previousTreatmentsFurther)}
              onChange={onChangeFormField}
            ></FormField>
          )}
        </Col>
        <Col>
          <FormField
            type='checkbox'
            name='therapy.hasInpatientTreatments'
            value='Has inpatient treatment?'
            checked={getFieldValue('boolean', patientInfoUpdate.therapy?.hasInpatientTreatments)}
            onChange={onChangeFormField}
            displayType='regularCheckbox'
          >
            <FormattedMessage id='patient.hasInpatientTreatments' />
          </FormField>
          <FormField
            type='checkbox'
            name='therapy.hasOperations'
            value='Has had operation?'
            checked={getFieldValue('boolean', patientInfoUpdate.therapy?.hasOperations)}
            onChange={onChangeFormField}
            displayType='regularCheckbox'
          >
            <FormattedMessage id='patient.hasOperations' />
          </FormField>
          <FormField
            type='checkbox'
            name='additionalQuestions.hasSleepDisorder'
            value='Has sleep disorders because of pains?'
            checked={getFieldValue('boolean', patientInfoUpdate.additionalQuestions?.hasSleepDisorder)}
            onChange={onChangeFormField}
            displayType='regularCheckbox'
          >
            <FormattedMessage id='patient.hasSleepDisorder' />
          </FormField>
        </Col>
      </Row>
    </Accordion.Content></>
  );
}