import { FormattedMessage } from 'react-intl';
import { Accordion, Icon } from 'semantic-ui-react';
import { AppointmentTable } from '../../../components/organisms';

type EditPatientProps = {
  activeIndex: number,
  handleClick: (arg0: any, arg1: any) => void,
  };

export default function PatientAppointment({activeIndex, handleClick }: EditPatientProps)  {

  return(
    <>
      <Accordion.Title
        active={activeIndex === 3}
        index={3}
        onClick={(event, data) => handleClick(event, data)}
      >
        <Icon name='dropdown' />
        <FormattedMessage id='appointments' />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 3}>
        <AppointmentTable />
      </Accordion.Content>
    </>
  );
}