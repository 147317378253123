/* eslint-disable jsx-a11y/anchor-is-valid */
import { Accordion, Icon } from 'semantic-ui-react';
import { File } from '../../../types/ninox.types';
import { FormField } from '../../../components/molecules';
import { Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import style from '../../patientInfo/EditPatient.module.scss';
import { ChangeEvent } from 'react';
import { getFieldValue } from '../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceSelector } from '../../auth/authSlice';
import { patientInfoSliceSelector, setPatientInfoUpdate } from '../patientInfoSlice';
import { toast } from 'react-toastify';
import { changeFileType, changeRelevantFileStatus, deletePatientFile, getFile, loadFiles, uploadPatientFile } from '../../../utils/apiCalls';

type EditPatientProps = {
  activeIndex: number,
  handleClick: (arg0: any, arg1: any) => void,
  };

export default function PatientFiles({activeIndex,handleClick}: EditPatientProps ){
  const cx = classNames.bind(style);
  const intl = useIntl();
  const dispatch = useDispatch();

  const { token } = useSelector(authSliceSelector);
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  const getFileChoices = (choices: string[][]): string[][] => choices.map((choice) => [choice[0], intl.formatMessage({ id: `patient.choices.file.${choice[0]}` })]);
  const typeDocumentChoices = getFileChoices([
    ['other', 'other'],
    ['prescription', 'prescription'],
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeRelevantFile = (event: React.ChangeEvent<any>, fileId: number): void => {    
    if (token) {
      if (patientInfoUpdate.id && patientInfoUpdate.id !== 0) {
        toast.info(intl.formatMessage({ id: 'patient.info.changingRelevant' }));
        changeRelevantFileStatus(patientInfoUpdate.id, fileId)
          .then(() => {
            toast.success(intl.formatMessage({ id: 'patient.info.relevantChanged' }));
            const updatedPatientFiles = patientInfoUpdate.file?.map((file: File) => (file.id === fileId ? { ...file, relevant: !file.relevant } : file));
            const updatePatient = { ...patientInfoUpdate, file: updatedPatientFiles };
            dispatch(setPatientInfoUpdate(updatePatient));
            return 'success';
          })
          .catch((error) => {
            toast.error(intl.formatMessage({ id: 'patient.error.changeRelevant' }));
            console.error(error.response);
          });
      } else {
        console.log('new patient should be created here');
      }
    } else {
      toast.error(intl.formatMessage({ id: 'patient.error.notLoggedIn' }));
    }
  };
    
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeFileType = (event: React.ChangeEvent<any>, fileId: number): void => {
    const type = event.target.value; 
    if (token) {
      if (patientInfoUpdate.id && patientInfoUpdate.id !== 0) {
        toast.info(intl.formatMessage({ id: 'patient.info.changingRelevant' }));    
        changeFileType(patientInfoUpdate.id, { fileId, type })
          .then(() => {
            toast.success(intl.formatMessage({ id: 'patient.info.fileTypeChanged' }));        
            const updatedPatientFiles = patientInfoUpdate.file?.map((file: File) => (file.id === fileId ? { ...file, type } : file));
            const updatePatient = { ...patientInfoUpdate, file: updatedPatientFiles };
            dispatch(setPatientInfoUpdate(updatePatient));
            return 'success';
          })
          .catch((error) => {
            toast.error(intl.formatMessage({ id: 'patient.error.changeFileType' }));        
            console.error(error.response);
          });
      } else {
        console.log('new patient should be created here');
      }
    } else {
      toast.error(intl.formatMessage({ id: 'patient.error.notLoggedIn' }));       
    }
  };
    
  /**
   * Uploads the file which has been selected
   * @param event The file input change event.
   */
  const onChangeFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!token) {
      toast.error(intl.formatMessage({ id: 'patient.error.notLoggedIn' }));
      return;
    }  
    if (!patientInfoUpdate.id || (patientInfoUpdate.id && patientInfoUpdate.id === 0)) {
      toast.error('No patient');
      return;
    }
    if (!event.target.files || event.target.files.length === 0) {
      toast.error('Upload triggered but file field empty.');
      return;
    } 
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    toast.info(intl.formatMessage({ id: 'patient.info.fileUploading' }));  
    uploadPatientFile(patientInfoUpdate.id, formData)
      .then(() => {
        toast.success(intl.formatMessage({ id: 'patient.info.fileUploaded' }));
        return 'success';
      })
      .catch((error) => {
        toast.error(intl.formatMessage({ id: 'patient.info.uploadFile' }));
        console.error(error.response);
        return Promise.reject(new Error('error'));
      })
      .then(() => {
        if (!token) {
          toast.error('Logged off after uploading file and before getting update for files.');
          return 'error';
        }
        return loadFiles(patientInfoUpdate.id)
          .then((updateResponse) => {
            toast.success(intl.formatMessage({ id: 'patient.info.fileUploadDone' }));
            const updatePatient = { ...patientInfoUpdate, file: updateResponse.data };
            dispatch(setPatientInfoUpdate(updatePatient));
            return 'success';
          })
          .catch((error) => {
            toast.error(intl.formatMessage({ id: 'patient.info.refreshFileList' }));
            console.error(error.response);
            return 'error';
          });
      })
      .catch(() => 'error');
  };
    
  const getMimeType = (fileName: string) => {
    const splitFilename = fileName.split('.');
    const fileExtension = splitFilename[splitFilename.length - 1];
    let mimeType = `image/${fileExtension}`;
    if (fileExtension === 'pdf') {
      mimeType = 'application/pdf';
    }  
    return mimeType;
  };
    
  const showFile = async (fileId: number, fileName: string): Promise<void> => {
    const fileResponse = await getFile(fileId);
    if (fileResponse.error) {
      console.error(fileResponse.message);
      toast.error('Es ist ein Fehler aufgetreten - Versuchen Sie es bitte erneut.');    
      return;
    }
  
    const base64Filedata = fileResponse.data.file;
    const byteCharacters = atob(base64Filedata);
    const byteNumbers = [];
    
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers.push(byteCharacters.charCodeAt(i));
    }
    
    const byteArray = new Uint8Array(byteNumbers); 
    const file = new Blob([byteArray], { type: `${getMimeType(fileName)};base64` }); 
    const fileURL = URL.createObjectURL(file); 
    window.open(fileURL, '_blank');
    URL.revokeObjectURL(fileURL);
  };
    
  const downloadFile = async (fileId: number, fileName: string): Promise<void> => {
    const fileResponse = await getFile(fileId);
    if (fileResponse.error) {
      console.error(fileResponse.message);
      toast.error('Es ist ein Fehler aufgetreten - Versuchen Sie es bitte erneut.');        
      return;
    }
    
    const base64Filedata = fileResponse.data.file;
    const a = document.createElement('a');
    a.href = `data:${getMimeType(fileName)};base64,${base64Filedata}`;
    a.download = fileName;
    a.click();
    a.remove();
  };

  const deleteFile = (file: File): void => {
    const patientId = patientInfoUpdate.id.toString();

    if (!patientId) {
      return;
    }
    if (!token) {
      console.error('File deletion triggered but user not logged in.');
      return;
    }
    toast.info(intl.formatMessage({ id: 'patient.info.deletingFile' }, { file: file.filename }));    
    deletePatientFile(patientId, file.id)
      .then(() => {
        toast.success(intl.formatMessage({ id: 'patient.info.fileDeleted' }, { file: file.filename }));    
        const updatePatient = { ...patientInfoUpdate, file: patientInfoUpdate.file?.filter((obj: File) => obj !== file) };
        dispatch(setPatientInfoUpdate(updatePatient));
      })
      .catch((error) => {
        toast.info(intl.formatMessage({ id: 'patient.error.deletingFile' }));    
        console.error('Error while file deletion', error.response);
      });
  };

  return(
    <>
      <Accordion.Title
        active={activeIndex === 12}
        index={12}
        onClick={(event, data) => handleClick(event, data)}
      >
        <Icon name='dropdown' />
        <FormattedMessage id='patient.files' />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 12}>
        <Row>
          <Col>
            <div className={cx({ Files: true })}>
              {patientInfoUpdate.file && patientInfoUpdate.file.length > 0 && (
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id='createdAt' />
                      </th>
                      <th>
                        <FormattedMessage id='patient.filename' />
                      </th>
                      <th style={{ width: '12rem' }}>
                        <FormattedMessage id='patient.fileType' />
                      </th>
                      <th style={{ textAlign: 'center' }}>
                        <FormattedMessage id='patient.medicalRelevant' />
                      </th>
                      <th>
                        <FormattedMessage id='patient.thumbnail' />
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {patientInfoUpdate.file.map((file: File, index: number) => (
                      <tr key={index}>
                        <td>{file.created}</td>
                        <td>{file.filename}</td>
                        <td>
                          <FormField
                            type='select'
                            name='fileType'
                            value={getFieldValue('string', file?.type)}
                            onChange={(event) => {
                              onChangeFileType(event, file.id);
                            }}
                            choices={typeDocumentChoices}
                          />
                        </td>
                        <td>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <FormField
                              type='checkbox'
                              name='therapy.takeOtherMedicines'
                              value='Takes other medicines?'
                              checked={getFieldValue('boolean', file.relevant)}
                              onChange={(event) => {
                                onChangeRelevantFile(event, file.id);
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          {file.thumbnail && (
                            <img className={cx({ Thumbnail: true })} src={`data:image/png;base64,${file.thumbnail}`} />
                          )}
                        </td>
                        <td>
                          <a
                            href='#'
                            onClick={(event) => {
                              event.preventDefault();
                              showFile(file.id, file.filename);
                            }}
                          >
                            <FormattedMessage id='show' />
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            onClick={(event) => {
                              event.preventDefault();
                              downloadFile(file.id, file.filename);
                            }}
                          >
                          Download
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            onClick={(event) => {
                              event.preventDefault();
                              if (
                              // eslint-disable-next-line no-alert
                                window.confirm(
                                  intl.formatMessage({ id: 'patient.deleteFileConfirmation' }, { file: file.filename }),
                                )
                              ) {
                                deleteFile(file);
                              }
                            }}
                          >
                            <FormattedMessage id='delete' />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {(!patientInfoUpdate.file || (patientInfoUpdate.file && !patientInfoUpdate.file.length)) && (
                <p>
                  <FormattedMessage id='patient.noFiles' />
                </p>
              )}
              <div className={cx({ FileField: true })}>
                <label htmlFor='file-field'>
                  <div className='btn btn-primary'>
                    <FormattedMessage id='patient.uploadFile' />
                  </div>
                </label>
                <input
                  type='file'
                  id='file-field'
                  name='file-field'
                  onChange={onChangeFile}
                  accept='.pdf, image/*, .doc, .docx, .odt, .xls, .xlsx, .ods'
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Accordion.Content></>
  );
}