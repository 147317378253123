/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
import React from 'react';
import classNames from 'classnames/bind';
import { Input } from '../../atoms';
import type { SelectOption } from '../../atoms/Input/Input';
import style from './FormField.module.scss';

const cx = classNames.bind(style);

type FormFieldProps = {
  type: string;
  placeholder?: string;
  name: string;
  value: string;
  children?: React.ReactNode;
  checked?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (event: React.ChangeEvent<any>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: React.MouseEvent<any>) => void;
  onMultiSelectChange?: (name: string, options: readonly SelectOption[]) => void;
  choices?: string[][];
  displayType?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
};

export default function FormField(props: FormFieldProps): JSX.Element {
  const formFieldClasses = cx({ FormField: true, Checkbox: props.type === 'checkbox' });
  const regularFormFieldClasses = cx({ FormField: true, Checkbox: props.type === 'checkbox', RegularCheckbox: true });

  if (props.displayType === 'regularCheckbox') {
    return (
      <div className={regularFormFieldClasses}>
        <Input
          type={props.type}
          name={props.name}
          checked={props.checked}
          placeholder={props.placeholder || ''}
          value={props.value}
          onChange={props.onChange}
          onMultiSelectChange={props.onMultiSelectChange}
          choices={props.choices}
          displayType='regularCheckbox'
        />
        <label htmlFor={props.name}>{props.children}</label>
      </div>
    );
  }

  return (
    <div className={formFieldClasses}>
      <label htmlFor={props.name}>{props.children}</label>
      <Input
        type={props.type}
        name={props.name}
        checked={props.checked}
        placeholder={props.placeholder || ''}
        value={props.value}
        onChange={props.onChange}
        onMultiSelectChange={props.onMultiSelectChange}
        choices={props.choices}
        min={props.min}
        max={props.max}
        disabled={props.disabled}
      />
    </div>
  );
}
