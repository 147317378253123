/* eslint-disable no-use-before-define */
import React from 'react';
import classNames from 'classnames/bind';
import style from './Text.module.scss';

const cx = classNames.bind(style);

type TextProps = {
  children: React.ReactNode;
};

export default function Text({ children }: TextProps): JSX.Element {
  const textClasses = cx({ Text: true, 'bg-dark': true });

  return <p className={textClasses}>{children}</p>;
}
