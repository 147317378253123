import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  fetchPatients, setPatients, setLoadPatients, setPatientFound
} from './patientSlice';
import {
  fetchPatientsListCall,
} from '../../utils/apiCalls';
import { authSliceSelector, logOut, setAuthError } from '../auth/authSlice';
import { ResponseData } from '../../types/return.types';
import _ from 'lodash';

function* fetchPatientsSaga(action: any) {
  try {
    yield put(setLoadPatients(true));
    const { token } = yield select(authSliceSelector);
    const res: ResponseData = yield call(fetchPatientsListCall, token, action.payload);
    // REFACTORING: check if the user is authenticat user else logout
    // if (res.error && res.error === 'Could not authenticate user') {
    //   yield put(setAuthError(res.error));
    //   yield put(logOut());
    // } else {
    //   yield put(setPatients(res));
    //   yield put(setLoadPatients(false));
    // }
    
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setPatients(res.data));
        yield put(setLoadPatients(false));
        yield put(setAuthError(null));

        if(action.payload.search !== '' && res.data.length === 0){ 
          yield put(setPatientFound(false));
        }else{
          yield put(setPatientFound(true));
        }
      }
    } else {
      // refactor the response that we received from backend
      if (res.response.status === 403) {
        yield put (logOut());
      }
    }
  } catch (err) {
    // toast.error(err, { position: 'top-center' });
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

export function* watchPatientsSaga() {
  yield takeLatest(fetchPatients.type, fetchPatientsSaga);
}
