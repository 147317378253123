/* eslint-disable no-use-before-define */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { UserState } from '../../../store/user/types';
import type { Remark } from '../../../types/ninox.types';
import { FormDialog, FormField } from '../../molecules';

type AddRemarkDialogProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  onAddRemark: (remark: Remark) => void;
  remark: Remark | null;
  user?: UserState;
  clearEditedRemark: () => void;
};

export function AddRemarkDialogElement(props: AddRemarkDialogProps): JSX.Element {
  const intl = useIntl();

  const emptyRemark = {
    text: '',
  };

  const [remark, setRemark] = useState<Remark>({ ...emptyRemark });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeFormField = (event: React.ChangeEvent<any>): void => {
    const { name, value } = event.target;
    setRemark((prevRemark) => ({ ...prevRemark, [name]: value }));
  };

  const onSave = () => {
    props.onAddRemark(remark);
    props.setShow(false);
    setRemark({ ...emptyRemark });
  };

  const onClose = () => {
    props.clearEditedRemark();
    setRemark({ ...emptyRemark });
  };

  useEffect(() => {
    if (props.remark) {
      setRemark(props.remark);
    }
  }, [props.remark]);

  return (
    <FormDialog
      title={intl.formatMessage({ id: 'patient.editRemark' })}
      show={props.show}
      setShow={props.setShow}
      onSave={onSave}
      onClose={onClose}
    >
      <FormField
        type='textarea'
        placeholder={intl.formatMessage({ id: 'patient.enterRemark' })}
        name='text'
        value={remark.text}
        onChange={onChangeFormField}
      >
        <FormattedMessage id='patient.message' />
      </FormField>
    </FormDialog>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => ({ user: state.user });

export const AddRemarkDialog = connect(mapStateToProps, null)(AddRemarkDialogElement);
