/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Patient, Remark } from '../../types/ninox.types';

const PATIENT_INFO_SLICE = 'PATIENT_INFO';

const initialState = {
  patientInfo: [],
  patientRemarks: [] as Remark[],
  patientInfoUpdate: [] as Patient[],
  loadPatient: false as boolean,
  allDoctorsList: [],
  patientDoctorsList: [],
};

export const patientInfoSlice = createSlice({
  name: PATIENT_INFO_SLICE,
  initialState,
  reducers: {
    setPatientInfoUpdate: (state, action) => {
      state.patientInfoUpdate = action.payload;
    },
    setLoadPatient: (state, action) => {
      state.loadPatient = action.payload;
    },
    setPatientFiles: (state, action) => {
      const patientWithFiles = { ...state.patientInfoUpdate, file: action.payload };
      state.patientInfoUpdate = patientWithFiles;
    },
    setPatientRemarks: (state, action) => {
      state.patientRemarks = action.payload;
    },
    setAllDoctorsList: (state, action) => {
      state.allDoctorsList = action.payload;
    },
    setPatientDoctorsList: (state, action) => {
      state.patientDoctorsList = action.payload;
      const patientWithDoctors = { ...state.patientInfoUpdate, doctorPatientRelation: action.payload.map((relation: any) => relation.id) };
      state.patientInfoUpdate = patientWithDoctors;
    },
    fetchPatientInfo: () => {},
    fetchPatientRemarks: () => {},
    fetchAllDoctorsList: () => {},
    fetchPatientDoctorsList: () => {},
  },
});

export const {
  setLoadPatient,
  setPatientInfoUpdate,
  fetchPatientInfo,
  fetchAllDoctorsList,
  fetchPatientDoctorsList,
  setPatientFiles,
  setAllDoctorsList,
  setPatientDoctorsList,
  fetchPatientRemarks,
  setPatientRemarks,
} = patientInfoSlice.actions;

export const patientInfoSliceSelector = (state: any) => state.patientInfo;

export default patientInfoSlice.reducer;