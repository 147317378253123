/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const PATIENTS_SLICE = 'PATIENTS';

const initialState = {
  patients: [] as Array<any>,
  loadPatients: false as boolean,
  isDisplayPatientInfo: false as boolean,
  patientsDoctorList: [],
  patientFound: true as boolean,
};

export const patientsSlice = createSlice({
  name: PATIENTS_SLICE,
  initialState,
  reducers: {
    setPatients: (state, action) => {
      state.patients = action.payload;
    },
    setPatientFound: (state, action) => {
      state.patientFound = action.payload;
    },
    setLoadPatients: (state, action) => {
      state.loadPatients = action.payload;
    },
    setUpdatedPatient: (state, action) => {
      state.patients = state.patients.map((patient) => ((patient.id === action.payload.id) ? { ...patient, ...action.payload } : patient));
    },
    setIsDisplayPatientInfo: (state, action) => {
      state.isDisplayPatientInfo = action.payload;
    },
    setPatientDoctorList: (state, action) => {
      state.patientsDoctorList = action.payload;
    },
    fetchPatients: () => {},
    fetchPatientsDoctorList: () => {},
  },
});

export const {
  setPatients,
  fetchPatients,
  setLoadPatients,
  setIsDisplayPatientInfo,
  fetchPatientsDoctorList,
  setPatientDoctorList,
  setUpdatedPatient,
  setPatientFound,
} = patientsSlice.actions;

export const patientsSliceSelector = (state: any) => state.patients;

export default patientsSlice.reducer;
