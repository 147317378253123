/* eslint-disable import/prefer-default-export */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { API_URL } from '../constants';
import type { Doctor } from '../types/ninox.types';
import type { AxiosReturn } from '../types/return.types';
import { getAPIToken } from '../utils';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Authorization = `Bearer ${getAPIToken()}`;

export class PracticeService {
  static async createPractice(doctor: Doctor): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .post('/doctor', doctor)
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async getAllPractices(): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .get('practice')
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async getPracticesByDoctor(id: number): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .get(`/practice/doctor/${id}`)
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async getPractice(id: number): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .get(`/practice/${id}`)
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async getAppointmentStats(): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .get('/practice/free-appointments-stats')
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async getFreeAppointments(practiceId: number): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .get(`/practice/${practiceId}/free-appointments`)
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }
}
