import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Header, Segment } from 'semantic-ui-react';
import style from './DoctorDetails.module.scss';
import { doctorsSliceSelector, fetchDoctorFreeSlots } from '../doctorsSlice';
import calculationsService from '../../../services/calculations.service';

const dropdownOptions = [
  {
    text: 'Online Erstgespräch',
    value: '0/free-online-appointments',
  },
  {
    text: 'Online Folgegespräch',
    value: '1/free-online-appointments',
  },
  {
    text: 'Offline Erstgespräch',
    value: '0/free-appointments',
  },
  {
    text: 'Offline Folgegespräch',
    value: '1/free-appointments',
  },
];

export default function DoctorInfoAppointments(props: any) {
  const [dropdownValue, setDropdownValue] = useState('1/free-online-appointments');
  const { doctorFreeSlots, doctorFreeDates } = useSelector(doctorsSliceSelector);
  const { doctorId, menuText } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDoctorFreeSlots({doctorId, url: dropdownValue}));
  }, [dropdownValue]);

  const onUpdateDropdownValue = (evt: any, { value }: any) => {
    setDropdownValue(value);
  };

  const renderDates = () => (!doctorFreeSlots.length ?
    (
      <Header>The doctor not have available slots</Header>
    )
    :
    doctorFreeDates.map((date: any, i:number) => {
      const dateAppointments = doctorFreeSlots?.filter(
        (slot: any) => calculationsService.isSameDay(date, slot.start),
      );

      if (dateAppointments && dateAppointments.length === 0) {
        return;
      }

      return (
        <Segment raised className={style.DoctorSecondRowContainer} key={String(date)}>
          <div className={style.DateContainer}>
            <p className={style.BlueTextBold}>{calculationsService.getDateFormat(date)}</p>
          </div>
          {dateAppointments && dateAppointments.length !== 0 && (
            <div>
              <div className={style.SlotContainer}>
                {dateAppointments.map((slot: any) => (
                  <div className={style.GreenSlot} key={slot.start}>
                    <span className={style.SlotTextGreen}>{`${calculationsService.getHourMinutesFormat(
                      slot.start,
                    )} - ${calculationsService.getHourMinutesFormat(slot.end)} Uhr`}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Segment>
      );
    }

    )

  );

  return (
    <div className={style.DoctorDropdownContainer}>
      <Dropdown
        placeholder={menuText}
        fluid
        selection
        options={dropdownOptions}
        value={dropdownValue}
        onChange={onUpdateDropdownValue}
      />
      {renderDates()}
    </div>
  );
}
